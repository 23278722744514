<template>
    <div class="project_page">
        <a href="/home">
            <div class = "top">
                <img class= logo src = "../assets/logo_p.png">
            </div>
        </a>
        <div class="left">
            <div class = "tab-selector">
                <p>所有项目</p>
            </div>
        </div>
        <div class="main">
            <div class="button-group">
                <div class = "create-button" @click="CreateProject">
                    <p>新建项目</p>
                </div>
            </div>
            <div class="project-group" >
                <div class="project-card" v-for="project in Projects">
                    <img :src = "project.cover? project.cover : require('../assets/zhanwei.png')" width="300px" height="300px" @click="openProject(project.id)">
                    <div class="footer">
                        <p>{{'未命名项目'  }}</p>
                        <div class="delete" style="position:absolute; margin-left: 80%;height: 80%;" @click="deleteProject(project.id)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../components/api';

export default {
    name : 'Projects',
    data() {
        return {
            Projects:[],
            user: {
                loggedIn: false,
                meta: {},
            },
            vip_info: {
                vip: false,
                count: 0
            },
        }
    },
    methods: {
        CreateProject(){
            if(!this.vip_info.vip)
            {
                if(this.Projects.length>=3){
                    this.openNotification("可以创建的项目达到上限");
                    return;
                }
            }
            else{
                if(this.Projects.length>=18){
                    this.openNotification("可以创建的项目达到上限");
                    return;
                }
            }
            let that = this;
            let request = indexedDB.open("myDatabase", 1);
            const id = this.generateUniqueRandomNumber();
            request.onsuccess = function(event) {
                let db = event.target.result;
                let transaction = db.transaction(["myStore"], "readwrite");
                let store = transaction.objectStore("myStore");
                
                // 写入数据
                store.put({ id: id, data: ""});
                
                // 读取数据
                that.Projects.push({ id: id, data: ""});
            };
        },
        generateUniqueRandomNumber() {
            let randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
            return randomNumber;
        },
        openNotification(title, text) {
          this.$vs.notification({
            color:'#FEC600',
            position:'top-right',
            title: title,
            text: text
          })
        },
        deleteProject(id) {
            let that = this;
            let request = indexedDB.open("myDatabase", 1);
            request.onsuccess = function(event) {
                let db = event.target.result;
                let transaction = db.transaction(["myStore"], "readwrite");
                let store = transaction.objectStore("myStore");
                
                // 定义要删除的项目的 ID
                let idToDelete = id; // 例如，我们想要删除 ID 为 1 的项目
                
                // 使用 delete 方法删除指定 ID 的项目
                let deleteRequest = store.delete(idToDelete);
                
                deleteRequest.onsuccess = function() {
                    console.log("Item with ID", idToDelete, "has been deleted.");
                    that.Projects = that.Projects.filter(v=>v.id!=id)
                };
                
                deleteRequest.onerror = function(event) {
                    console.error("Error deleting item with ID", idToDelete, ":", event.target.errorCode);
                };
            };
        },
        openProject(id){
            this.$router.push({ name: 'sketch', params:{projectId:id} });
        },
        async initializeUser(force = false) {
            const self = this;
            const userInfo = await this.$guard.trackSession();
            if (userInfo === null) {
            self.user.loggedIn = false;
            self.user.meta = {};
            this.openNotification('请先注册或登陆');
            this.$router.push({ name: 'home' });
            } else {
            self.user.meta = userInfo;
            self.user.loggedIn = true;
            self.updateVipInfo();
            }
        },
        updateVipInfo(){
            let that = this;
            api.User.fetchUserInfoByID(this.user.meta.id).then((res) => {
            that.vip_info = res;
            })
        },
    },
    mounted() {
        this.$nextTick(()=>{
            let request = indexedDB.open("myDatabase", 1);
            let that = this;
            request.onupgradeneeded = function(event) {
                let db = event.target.result;
                if (!db.objectStoreNames.contains("myStore")) {
                    db.createObjectStore("myStore", { keyPath: "id" });
                }
            };
            request.onsuccess = function(event) {
                let db = event.target.result;
                let transaction = db.transaction(["myStore"], "readonly");
                let store = transaction.objectStore("myStore");
                
                
                // 使用 openCursor() 方法遍历 object store
                store.openCursor().onsuccess = function(event) {
                    let cursor = event.target.result;
                    
                    if (cursor) {
                        // 如果找到了一个数据项，则将其添加到数组中
                        that.Projects.push(cursor.value);
                        
                        // 继续下一个数据项
                        cursor.continue();
                    } else {
                    }
                };
            };
            this.initializeUser();
        })
    }


}
</script>
<style lang="scss" scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.project_page{
    width: 100vw;
    height: 200vh;
    position:absolute;
    display: flex;
    flex-direction: column;
    background: rgb(46, 46, 46);
}
.top{
    position: absolute;
    width: 100vw;
    height: 4vh;
    background: black;
    border-bottom: 1px solid rgb(59, 59, 59);
}
.left{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    left:0px;
    top: 4vh;
    width: 10vw;
    height: 196vh;
    background-color: black;
    border-right: 1px solid rgb(59, 59, 59);;
}
.main{
    position: absolute;
    width: 90vw;
    height: 196vh;
    left: 10vw;
    top: 4vh;
    background-color: black;
}
.logo{
    position: relative;
    top:17%;
    left:15px;
    height: 70%; 
}
.tab-selector{
    position: relative;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
    color: azure;
    margin-top: 5%;
    width: 80%;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(59, 59, 59);
    border: 1px solid transparent; /* 预设一个透明的边框 */
    transition: border 0.3s ease;  /* 修改过渡属性 */
    cursor: pointer;
}
.tab-selector:hover{
    border:1px solid rgb(99, 99, 99);
}
.button-group{
    position: relative;
    color:azure;
}
.create-button{
    position: relative;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
    margin-top: 20px;
    margin-left: 30px;
    width: 100px;
    border-radius: 5px;
    background-color: rgb(44, 44, 44);
    border: 1px solid transparent; /* 预设一个透明的边框 */
    transition: border 0.3s ease;  /* 修改过渡属性 */
    cursor: pointer;
}
.create-button:hover{
    border: 1px solid rgb(241, 165, 22);
}
.project-group{
    position: relative;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    width: 100%;
    margin-top: 2%;
    margin-left: 2%;
    height: auto;
    overflow: hidden;
}
.project-card{
    position: relative;
    height: 330px;
    width: 300px;
    border-radius: 10px;
    border: 1px solid transparent; /* 预设一个透明的边框 */
    transition: border 0.3s ease;  /* 修改过渡属性 */
    cursor: pointer;
    overflow: hidden;
    margin: 5px 10px 5px 10px;
}
.project-card:hover {
    border: 1px solid rgb(241, 165, 22);
}
.footer{
    position: absolute;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
    top: 250px;
    width: 100%;
    height: 80px;
    color: aliceblue;
    background-color: rgb(44, 44, 44);
}
p {
  height: 38px;           /* 你可以设置为你需要的高度 */
  line-height: 38px;      /* 这应该与上面的高度相同 */
  margin: 0;               /* 移除默认的外边距 */
}
</style>