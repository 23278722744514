<template>
  <div class="add2board-modal">
    <div>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title" style="color: rgb(27, 27, 27);">{{ $t("Add2BoardModalCardTitle") }}</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <FileUpload
                :previewImageURL="pin.url"
              ></FileUpload>
            </div>
            <div class="column">
              <FilterSelect
                :allOptions="boardOptions"
                :sub_id="sub_id"
                v-on:selected="onSelectBoard"
              ></FilterSelect>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$parent.close()">{{ $t("closeButton") }}</button>
          <vs-button
            gradient
            size = 'large'
            @click="doAdd2Board"
          >
          {{ $t("Add2BoardModalCardButton") }}
          </vs-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../api';
import FileUpload from './FileUpload.vue';
import FilterSelect from './FilterSelect.vue';


export default {
  name: 'Add2Board',
  props: ['pin', 'username', 'sub_id'],
  components: {
    FileUpload,
    FilterSelect,
  },
  data() {
    return {
      boardOptions: [],
      boardIds: [],
    };
  },
  created() {
    this.fetchBoardList();
  },
  methods: {
    doAdd2Board() {
      if (this.boardIds.length > 0) {
        const promises = [];
        this.boardIds.forEach(
          (boardId) => {
            promises.push(
              API.Board.addToBoard(boardId, [this.pin.id]),
            );
          },
        );
        Promise.all(promises).then(
          () => {
            this.$buefy.toast.open('Succeed to add pin to boards');
            this.$parent.close();
          },
          () => {
            this.$buefy.toast.open(
              {
                message: 'Failed to add pin to boards',
                type: 'is-danger',
              },
            );
          },
        );
      }
    },
    onSelectBoard(boardIds) {
      this.boardIds = boardIds;
    },
    fetchBoardList() {
      API.Board.fetchFullList(this.sub_id).then(
        (resp) => {
          const boardOptions = [];
          resp.data.forEach(
            (board) => {
              const boardOption = { name: board.name, value: board.id };
              boardOptions.push(boardOption);
            },
          );
          this.boardOptions = boardOptions;
        },
        () => {
          // console.log('Error occurs while fetch board full list');
        },
      );
    },
  },
};
</script>
