<template>
  <div class="editor-buttons">
    <span class="icon-container" v-if="inOwnedBoard" @click="removeFromBoard" @click.stop="openPreview">
        <!-- <b-icon
          type="is-light"
          icon="minus-box"
          custom-size="mdi-24px">
        </b-icon> -->
        <i class="draw-icon iconfont icon-xingzhuang-tuoyuanxing"></i>
    </span>
    <span class="icon-container" @click="addToBoard" @click.stop="openPreview" v-if="isloggedIn">
        <!-- <b-icon
          type="is-light"
          icon="plus-box"
          custom-size="mdi-24px">
        </b-icon> -->
        <i class="draw-icon icomoon icon-heart"></i>
    </span>
    <span class="icon-container" @click="deletePin" @click.stop="openPreview" v-if="isOwner">
        <!-- <b-icon
          type="is-light"
          icon="delete"
          custom-size="mdi-24px">
        </b-icon> -->
        <i class="draw-icon icomoon icon-bin"></i>
    </span>
    <span class="icon-container" v-if="isOwner" @click="editPin" @click.stop="openPreview">
      <!-- <b-icon
        type="is-light"
        icon="pencil"
        custom-size="mdi-24px">
      </b-icon> -->
      <i class="draw-icon icomoon icon-pencil2"></i>
    </span>
    <span class="icon-container" @click="copyToClipboard" @click.stop="openPreview">
      <!-- <b-icon
        type="is-light"
        icon="pencil"
        custom-size="mdi-24px">
      </b-icon> -->
      <i class="draw-icon icomoon icon-copy"></i>
    </span>
    <span class="icon-container" @click="gotoDraw" @click.stop="openPreview">
      <!-- <b-icon
        type="is-light"
        icon="pencil"
        custom-size="mdi-24px">
      </b-icon> -->
      <i class="draw-icon icomoon icon-redo2"></i>
    </span>
    <br>
    <span class="description-t">
    {{ pin.description }}
    </span>
  </div>
</template>

<script>
import API from '../api';
import modals from '../modals';
import { Message } from 'element-ui';

export default {
  name: 'Editor',
  props: {
    currentBoard: {
      type: Object,
      default() {
        return {};
      },
    },
    currentUsername: {
      default: null,
      type: String,
    },
    sub_id: {
      default: null,
      type: String,
    },
    pin: {
      default() {
        return {};
      },
      type: Object,
    },
    description: {
      default: null,
      type: String,
    },
    currentUserId: {
      default: null,
      type: String,
    },
  },
  computed: {
    isOwner() {
      return this.pin.owner_id === this.currentUserId;
    },
    isloggedIn() {
      return this.currentUsername !== null;
    },
    inOwnedBoard() {
      // console.log(this.currentBoard, this.currentUsername);
      return (
        Object.values(this.currentBoard).length !== 0
        && this.currentBoard.subid === this.currentUserId
      );
    },
  },
  methods: {
    addToBoard() {
      modals.openAdd2Board(this, this.pin, this.currentUsername, this.sub_id);
    },
    removeFromBoard() {
      this.$buefy.dialog.confirm({
        message: 'Remove Pin from Board?',
        onConfirm: () => {
          API.Board.removeFromBoard(this.currentBoard.id, [this.pin.id]).then(
            () => {
              this.$buefy.toast.open('Pin removed');
              this.$emit('pin-remove-from-board-succeed', this.pin.id);
            },
            () => {
              this.$buefy.toast.open(
                { type: 'is-danger', message: 'Failed to Remove Pin' },
              );
            },
          );
        },
      });
    },
    editPin() {
      const props = {
        username: this.currentUsername,
        sub_id: this.currentUserId,
        existedPin: this.pin,
        isEdit: true,
        avatar: this.pin.avatar,
      };
      modals.openPinEdit(
        this,
        props,
      );
    },
    deletePin() {
      this.$buefy.dialog.confirm({
        message: 'Delete this Pin?',
        onConfirm: () => {
          API.Pin.deleteById(this.pin.id, this.currentUserId).then(
            () => {
              this.$buefy.toast.open('Pin deleted');
              this.$emit('pin-delete-succeed', this.pin.id);
            },
            () => {
              this.$buefy.toast.open(
                { type: 'is-danger', message: 'Failed to delete Pin' },
              );
            },
          );
        },
      });
    },
    copyToClipboard(){
      const textToCopy = this.pin.description;
      let that = this;
      this.$copyText(textToCopy).then(() => {
        Message.info('复制到剪贴板成功');
      }).catch(() => {
        Message.info('复制到剪贴板失败');
      });
    },
    openPreview(pinItem) {
    },
    gotoDraw(){
      console.log(this.pin.large_image_url);
      this.$router.push({ name: 'sketch', params:{url:this.pin.large_image_url } });
    }
  },
};
</script>

<style lang="scss" scoped>
@import './editor';
</style>
