<template>
    <div class="board-modal">
      <div>
        <div class="modal-card" style="width: auto; ">
          <header class="modal-card-head" style="background-color: rgb(43, 70, 221);">
            <p class="modal-card-title">请分享您的邀请信息</p>
          </header>
          <section class="modal-card-body">
            <div >
                <h1 class = "invite-amount">您可获得20点数，被邀请人可以获得额外10点数</h1>
            </div>
            <div class = "copy-rigion">
                <h1 class = "invite">使用链接：https://www.arti-id.com</h1>
                <h1 class = "invite">邀请码：{{invite_code}}</h1>
            </div>
            <div style="display: flex;flex-direction: row;">
                <vs-button type="filled" color="primary" gradient @click="copyToClipboard">一键复制邀请码和链接</vs-button>
            </div>
          </section>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import api from '../api';
  import md5 from 'md5';
  import { EventBus } from '../event-bus';
  import { Message } from 'element-ui';
  
  export default {
    data() {
      return {
        name: 'Invite',
        amount: 19.9, // 支付金额
        qrCodeUrl: '', // 支付二维码URL
        invite_code: '',
      };
    },
    props: {
      user_id: {
        type: String,
        default: null,
      },
      user_name: {
        type: String,
        default: null,
      },
    },
    created() {
        api.User.fetchUserInfoByID(this.user_id).then((res) => {
            // console.log("res: ", res);
            this.invite_code = res.invite_code;
        })
    },
    methods: {
    copyToClipboard(){
      const textToCopy = '试用链接：https://www.arti-id.com 邀请码：' + this.invite_code;
      let that = this;
      this.$copyText(textToCopy).then(() => {
        Message.info('复制到剪贴板成功');
      }).catch(() => {
        Message.info('复制到剪贴板失败');
      });
    },
    },
  };
  </script>

<style>
.invite-amount {
  font-size: 24px;
  font-weight: bold;
  color: #464646; /* 设置为红色 */
  text-align: center;
}
.invite{
    font-size:18px;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    color:#464646
}
.copy-rigion{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background-color: #e6e6e6;
    border-radius: 8px;
}
.modal-card-title
{
  color: white;
  font-weight: bold;
  text-align: center;
}
</style>