<template>
  <div>
    <nav class="navbar is-dark  " role="navigation" aria-label="main navigation">
      <div class="container">
        <div id="ArtiNav" class="navbar-menu" :class="{ 'is-active': active}">
          <div class="navbar-start">
            <!-- <a class="navbar-item" :href="bookmarklet">
              {{ $t("bookmarkletLink") }}
            </a> -->  
            <div
              v-if="user.loggedIn"
              class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                {{ $t("createLink") }}
              </a>
              <div class="navbar-dropdown is-hoverable is-boxed">
                <a
                  @click="createPin"
                  class="navbar-item">
                  {{ $t("pinLink") }}
                </a>
                <a
                  @click="createBoard"
                  class="navbar-item">
                  {{ $t("boardLink") }}
                </a>
              </div>
            </div>
            <!-- <a class="navbar-item"
              @click="openInvite">
              {{ $t("inviteLink") }}
            </a> -->
              <!-- <div class="navbar-dropdown">
                <router-link
                  :to="{ name: 'boards4user', params: {username: user.meta.username} }"
                  class="navbar-item">
                  {{ $t("boardsLink") }}
                </router-link>
                <router-link
                  :to="{ name: 'user', params: {user: user.meta.username} }"
                  class="navbar-item">
                  {{ $t("pinsLink") }}
                </router-link>
                <a
                  @click="openInvite"
                  class="navbar-item">
                  {{ $t("inviteLink") }}
                </a>
                <a
                  @click="redirecttoURL()"
                  class="navbar-item">
                  {{ $t("profileLink") }}
                </a>
              </div> -->
            <router-link
                  :to="{ name: 'sketch'}"
                  class="navbar-item"
                  v-if="user.loggedIn">
                  {{$t("sketch")}}
            </router-link>
            <router-link
                    :to="{ name: 'user', params: {user: user.meta.username} }"
                    class="navbar-item"
                    v-if="user.loggedIn">
                    我的图库
            </router-link>
            <!-- <router-link
                  :to="{ name: 'tbdesign'}"
                  class="navbar-item"
                  v-if="user.loggedIn">
                  电商定制
              </router-link> -->
          </div>
          <div class="navbar-end">
            <router-link
              :to="{ name: 'search' }"
              class="navbar-item">
              <b-icon
                type="is-dark"
                icon="magnify"
                custom-size="mdi-24px">
              </b-icon>
            </router-link>
            <div
              class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                <b-icon
                  type="is-dark"
                  icon="translate"
                  custom-size="mdi-24px">
                </b-icon>
              </a>
              <div class="navbar-dropdown">
                <a
                  v-for="locale in $i18n.availableLocales"
                  :key="`locale-${locale}`"
                  @click="setLocale(locale)"
                  class="navbar-item">
                  {{ langs[locale] }}
                </a>
              </div>
            </div>
            <div class=head @click="changeHead">
              <vs-tooltip v-show="user.loggedIn" shadow bottom>
                <vs-avatar>
                  <img :src="user.meta.photo" alt="">
                </vs-avatar>
                <template #tooltip>
                  <div class="content-tooltip">
                    <div class="body">
                      <div class="text">
                          Arti 已为您生成
                        <span>
                          {{ vip_info.generate_count }}
                        </span>
                      </div>
                      <vs-avatar circle size="50" @click="activeTooltip1=!activeTooltip1">
                        <img :src="user.meta.photo" alt="">
                      </vs-avatar>
                      <div class="text">
                          Arti 库中保存的资产
                        <span>
                          {{ vip_info.pins_count }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </vs-tooltip>
            </div>
            <div class="navbar-item">
              <div class="buttons" style="text-align: center;">
                <!-- <a
                  @click="signUp"
                  v-show="!user.loggedIn"
                  class="button is-primary">
                  <strong>{{ $t("signUpLink") }}</strong>
                </a> -->
                <!-- <div v-show="!user.loggedIn" style="margin-top: -10px;">
                  <vs-button
                    gradient
                    size="large"
                    @click="logIn"
                  > 
                  {{ $t("logInLink") }}
                  </vs-button>
                </div>
                <div v-show="user.loggedIn" style="margin-top: -10px;">
                  <vs-button
                    gradient
                    @click="logOut"
                  > 
                  {{ $t("logOutLink") }}
                  </vs-button>
                </div> -->
                <div v-show="user.loggedIn" style="margin-top: -10px; margin-left: 20px;">
                  <vs-tooltip bottom>
                      <vs-button
                      border
                      color="#FAFF00"
                      @click ="Pay"
                    > 
                    加油包购买
                    </vs-button>
                    <template #tooltip>
                      <div class="content-tooltip">
                        <strong><h4 style="color:aliceblue; font-size:20px">关于老用户的数据迁移</h4></strong>
                        <p>老vip用户按照剩余比例迁移</p>
                        <p>对迁移有问题的请联系客服QQ</p>
                        <p>QQ: 248977265</p>
                      </div>
                    </template>
                  </vs-tooltip>
                </div>
                <div v-show="user.loggedIn" style="margin-top: -10px; margin-left: 10px;">
                  <span class="payment-amount">{{ '剩余点数：' + vip_info.count }}</span>
                </div>
                <!-- <a
                  v-show="user.loggedIn"
                  v-on:click="logOut"
                  class="button is-light">
                  {{ $t("logOutLink") }}
                </a> -->
              </div>
            </div>
          </div>
        </div>
        <div id="authing-guard-container"></div>
      </div>
    </nav>
    <div class="logo">
      <a class = "logo-a" href="/" >
        <img src="../assets/logo-light.png" height="10" style="margin-top: 15px;height: 30px;"> 
      </a>
    </div>
  </div>

</template>

<script>
import localeUtils from '@/components/utils/i18n';
import api from './api';
import modals from './modals';
import { EventBus } from './event-bus';

export default {
  name: 'p-header',
  data() {
    return {
      active: false,
      user: {
        loggedIn: false,
        meta: {},
      },
      langs: localeUtils.langCode2Name,
      submitter: null,
      vip_info: {
        vip: false,
        count: 0
      }
    };
  },
  computed: {
    bookmarklet() {
      const url = new URL(window.location);
      const host = url.origin;
      return `javascript:void((function(d){var s=d.createElement('script');s.id='pinry-bookmarklet';s.src='${host}/static/js/bookmarklet.js?'+Math.random()*10000000000000000;d.body.appendChild(s)})(document));`;
    },
  },
  methods: {
    async changeHead(){
      const authenticationClient = await this.$guard.getAuthClient();
      const userProfile = await authenticationClient.uploadAvatar();
      this.user.meta = userProfile;
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('localeCode', locale);
    },
    toggleMenu() {
      this.active = !this.active;
    },
    onLoginSucceed() {
      this.initializeUser(true);
    },
    onSignUpSucceed() {
      this.initializeUser(true);
    },
    logOut() {
      this.$guard.logout();
      this.user.loggedIn = false;
    },
    logIn() {
      let that = this;
      // modals.openSignUp(this, this.onSignUpSucceed);
      this.$guard.start("#authing-guard-container").then((userInfo) => {
    });
    },
    createPin() {
      modals.openPinEdit(
        this,
        { sub_id: this.user.meta.id,
          avatar: this.user.meta.photo
         },
      );
    },
    createBoard() {
      modals.openBoardCreate(this);
    },
    Pay() {
      modals.openPay(this, this.user.meta.id);
    },
    openNotification(title, text) {
        this.$vs.notification({
          color:'#7d33ff',
          position:'top-right',
          title: title,
          text: text
        })
      },
    // signUp() {

    // },
    async initializeUser(force = false) {
      const self = this;
      const userInfo = await this.$guard.trackSession();
      if (userInfo != null) {
        this.user.meta = userInfo;
        this.user.loggedIn = true;
        api.User.signUp(this.user.meta.username===null? 'user' : this.user.meta.username, this.user.meta.id,'null').then((res) => {
                self.vip_info = res;
                EventBus.$emit('vip-info-loaded', self.vip_info);
                // console.log("res: ", res);
              })
        EventBus.$emit('user-loaded', self.user)
      }
      else{
        this.openNotification('请先注册或登陆');
        this.$router.push({ name: 'home' });
        this.$guard.start("#authing-guard-container").then((userInfo) => {
        this.user.meta = userInfo;
        this.user.loggedIn = true;
        // console.log("userInfo: ", userInfo);
        // if(this.user.meta.username != null){
        //   this.$guard.getAuthClient().then((ACobj) => {
        //     ACobj.getUdfValue().then((res) => {
        //       if (res.invited_code === undefined){
        //         ACobj.setUdfValue({invited_code: 'xxxxxxxxxx'}).then((res) => {
        //       })
        //       }
        //       if (res.invited_code === undefined || res.invited_code === 'xxxxxxxxxx') {
                
        //         api.User.signUp(this.user.meta.username===null? 'user' : this.user.meta.username, this.user.meta.id,'null').then((res) => {
        //         self.vip_info = res;
        //         EventBus.$emit('vip-info-loaded', self.vip_info);
        //         // console.log("res: ", res);
        //       })
        //       }
        //       else{
        //         api.User.signUp(this.user.meta.username===null? 'user' : this.user.meta.username, this.user.meta.id, res.invited_code).then((res) => {
        //           self.vip_info = res;
        //           EventBus.$emit('vip-info-loaded', self.vip_info);
        //           // console.log("res: ", res);
        //         })
        //       }
        //     })
        //   }).catch((err) => {
        //     api.User.signUp(this.user.meta.username===null? 'user' : this.user.meta.username, this.user.meta.id, 'null').then((res) => {
        //           self.vip_info = res;
        //           EventBus.$emit('vip-info-loaded', self.vip_info);
        //           // console.log("res: ", res);
        //         })
        //   })
        //   this.$guard.hide();
        // }
        // api.User.signUp(this.user.meta.username===null? 'user' : this.user.meta.username, this.user.meta.id).then((res) => {
        //   // console.log("res: ", res);
        // })
      }).then(() =>{
        EventBus.$emit('user-loaded', self.user)
      }
      )
      }

      // api.User.fetchUserInfo(force).then(
      //   (user) => {
      //     if (user === null) {
      //       self.user.loggedIn = false;
      //       self.user.meta = {};
      //     } else {
      //       self.user.meta = user;
      //       self.user.loggedIn = true;
      //     }
      //   },
      // ).then(
      //   () => {
      //     EventBus.$emit('user-loaded', self.user);
      //     // console.log(self.user);
      //   },
      // );
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    redirecttoURL(){
      window.location.href = 'https://wacxvw9u4mnt.authing.cn/u?app_id=649e44eb83dd04e8935bd3a2';
    },
    openInvite(){
      modals.openInvite(this, this.user.meta.username, this.user.meta.id);
    },
    updateVipInfo(){
      let that = this;
      api.User.fetchUserInfoByID(this.user.meta.id).then((res) => {
        // console.log("res: ", res);
        that.vip_info = res;
      })
    },
    // scrollHandler() {
    //   let nav = document.querySelector("nav");
    //   nav.classList.toggle("sticky", window.scrollY > 0);
    // },
  },
  mounted() {
    // window.addEventListener("scroll",this.scrollHandler);
    EventBus.$on('updateHistory', this.updateVipInfo);
  },
  beforeMount() {
    this.initializeUser();
  },
  destroyed(){
    // window.removeEventListener("scroll", this.scrollHandler);
  }
};
</script>

<style scoped>

@media (max-width: 600px) {
    html {
        font-size: 8px;  /* 在小屏幕上，1rem将等于14px */
    }
}
@media (max-width: 1200px) {
    html {
        font-size: 14px;  /* 在小屏幕上，1rem将等于14px */
    }
}
@media (max-width: 1440px) {
    html {
        font-size: 18px;  /* 在小屏幕上，1rem将等于14px */
    }
}
.logo{
  position:absolute;
  top: 0px;
  z-index: 100;
  width: auto;
  margin-left: 48%;
}
.p-header {
  position: fixed;
  top: 0;
  left: 0;    
  width: 100vw;
  /* 弹性布局 */
  display: flex;
  /* 将元素靠边对齐 */
  justify-content: space-between;
  align-items: center;

  z-index: 1;
  /* 动画过渡 */
  transition: 0.6s;
  font-size: 1.1rem;
  z-index: 2;
}

a:hover{
  color: #FAFF00;
}


nav {
  
  width: 100%;
  /* height: 100%; */
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(0,0,0,0);
  transition: 0.6s;
  font-size: 120%;
}

/* .sticky{
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
} */

.payment-amount {
  font-size: 12px;
  font-weight: bold;
  color: #FAFF00; /* 设置为红色 */
  text-align: center;
}
.avatar{
  margin-left: 20px;
  margin-top: -10px;
  text-align: center;
}
.avatar-image{
  width: 35px;
  height: 35px;
  border-radius: 50%; /* 这会使图像变成圆形 */
  object-fit: cover; /* 这确保图像内容被正确裁剪并填充整个元素 */
}
/* .content-tooltip
{
  .body
  {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .vs-avatar-content
    {
      margin-top: -30px;
      border: 3px solid getVar('theme-layout');
      box-shadow: 0px 4px 15px 0px rgba(0,0,0,.1);
    }
    .text
    {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: .80rem;
      padding: 10px;
      font-weight: normal;
      .span
      {
        font-weight: bold;
        font-size: .7rem;
      }
    }

    .footer
    {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .h4
    {
      padding: 8px;
      margin: 0px;
      text-align: left;
    }
    .p
    {
      text-align: left;
      padding: 0px;
      margin: 0px;
      line-height: 1rem;
      padding-bottom:5px;
      padding-left: 8px;
    }
  }
        
} */
.head:hover{
  cursor: pointer;
}
      
</style>
