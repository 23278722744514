<template>
    <!-- 其他页面内容 -->
    <PrivacyPolicy />
    <!-- 其他页面内容 -->
  </template>
  
  <script>
  import PrivacyPolicy from '../components/PrivacyPolicy.vue';
  
  export default {
    name: 'Privacy',
    components: {
      PrivacyPolicy,
    },
    // 其他逻辑和代码
  };
  </script>
  