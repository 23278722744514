<template>
  <div class="editor">
    <div class="editor-buttons-board">
      <span class="icon-container" @click="deleteBoard">
        <i class="draw-icon icomoon icon-bin"></i>
      </span>
      <span class="icon-container" @click="editBoard">
        <i class="draw-icon icomoon icon-pencil2"></i>
      </span>
    </div>
  </div>
</template>

<script>
import API from '../api';
import modals from '../modals';
import bus from '../utils/bus';

export default {
  name: 'BoardEditor',
  props: {
    board: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  methods: {
    onBoardSaved() {
      this.$emit('board-save-succeed');
    },
    editBoard() {
      modals.openBoardEdit(
        this,
        this.board,
        this.onBoardSaved,
      );
    },
    deleteBoard() {
      this.$buefy.dialog.confirm({
        message: 'Delete this Board?',
        onConfirm: () => {
          API.Board.delete(this.board.id).then(
            () => {
              this.$buefy.toast.open('Board deleted');
              this.$emit('board-delete-succeed', this.board.id);
              bus.bus.$emit(bus.events.refreshBoards);
            },
            () => {
              this.$buefy.toast.open(
                { type: 'is-danger', message: 'Failed to delete Board' },
              );
            },
          );
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './editor';
</style>
