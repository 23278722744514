<template>
    <div class="draw">
      <DrawingBoard></DrawingBoard>
    </div>
</template>
  
<script>
  import DrawingBoard from '../components/drawingBoard.vue'

  export default {
    name: 'DrawingBoardView',
    components: {
    DrawingBoard
    }
  }
</script>

<style scoped lang="scss">
  // .draw {
  //   width: 90%;
  //   height: 100%;
  // }
</style>
