<template>
<div class="body">
    <div class="container">
        <div class="register-box">
            <h2 class="register-title">
                <span>没有账号，去</span>注册
            </h2>
            <div class="input-box">
                <input type="text" v-model="username" placeholder="手机/邮箱">
                <input type="password" placeholder="密码">
                <div style="position: relative;">
                    <input type="password" placeholder="短信/邮箱验证码">
                    <button class="yanzhengma" @click="startCountdown" :disabled="isCounting" style="position: absolute; top: -15px;left:145px;width: 100px;height:30px;padding: 0px 0px;"> {{ isCounting ? countdown+'s' : '获取验证码' }}</button>
                </div>
            </div>
            <div style="height:50px; display: flex; flex-direction: row;position: relative; text-align: center;justify-content: center;">
                <vs-checkbox>
                </vs-checkbox>
                <p style="text-align: center;margin-top: 13px;margin-left: 5px;color: aliceblue;">
                    我已阅读并同意
                </p>
                <a href="https://www.arti-id.com/privacy" style="color:#dc9fff">Arti用户隐私协议</a>
            </div>
            <button>注册</button>
        </div>
        <div class="login-box slide-up">
            <div class="center">
                <h2 class="login-title">
                    <span>已有账号，去</span>登录
                </h2>
                <div class="input-box">
                    <input type="text" placeholder="手机号/用户名/邮箱">
                    <input type="password" placeholder="密码">
                </div>
                <div class="input-box-yanzhegnma">
                    <input type="text" placeholder="手机号/邮箱">
                    <input type="password" placeholder="短信/邮箱验证码">
                    <button class="yanzhengma" style="position: absolute; top: 15px;left:120px;width: 100px;height:30px;padding: 0px 0px;">获取验证码</button>
                </div>
                <div class="arrow-container-left" v-show=!inleft @click="changetomima">
                    <svg t="1693370187033" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3988" width="150" height="150"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" fill="#999999" p-id="3989"></path></svg>
                </div>
                <div class="arrow-container-right" v-show=inleft @click="changetoyanzhengma">
                    <svg t="1693370187033" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3988" width="150" height="150"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" fill="#999999" p-id="3989"></path></svg>
                </div>
                <div style="height:50px; display: flex; flex-direction: row;position: relative; text-align: center;justify-content: center;">
                    <vs-checkbox>
                    </vs-checkbox>
                    <p style="text-align: center;margin-top: 13px;margin-left: 5px;">
                        我已阅读并同意
                    </p>
                    <a href="https://www.arti-id.com/privacy" style="color: #4a61af;">Arti用户隐私协议</a>
                </div>  
                <button>登录</button>
            </div>
        </div>
    </div>
    <ul class="bg-squares">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>
</template>

<script>
import { AuthenticationClient } from 'authing-js-sdk'
import { EmailScene } from 'authing-js-sdk'

const authenticationClient = new AuthenticationClient({
    appId: '649e44eb83dd04e8935bd3a2', // 应用 ID
    secret: '7944a0841bff4040a93e3af5c6796ac7',// 应用 Secret
    appHost: 'https://wacxvw9u4mnt-demo.authing.cn',// 应用对应的用户池域名
    redirectUri: 'https://www.arti-id.com',// 认证完成后的重定向目标 URL
})

export default {
    name: "Login",
    data() {
        return {
            // 登录注册切换
            isLogin: true,
            // 登录注册切换动画
            isSlideUp: false,
            username: "",
            inleft: true,
            isCounting: false, // 是否正在倒计时
            countdown: 60, // 倒计时剩余时间
        }
    },
    methods: {
        changetoyanzhengma(){
            this.inleft=false;
            let inputbox1 = document.querySelector('.login-box .input-box');
            let inputbox2 = document.querySelector('.login-box .input-box-yanzhegnma');
            inputbox1.style.transform = 'translateX(-300px)';
            inputbox2.style.left = '0px'
        },
        changetomima(){
            this.inleft=true;
            let inputbox1 = document.querySelector('.login-box .input-box');
            let inputbox2 = document.querySelector('.login-box .input-box-yanzhegnma');
            inputbox1.style.transform = 'translateX(0px)';
            inputbox2.style.left = '300px'
        },
        startCountdown() {
            if (!this.isCounting) {
                this.isCounting = true;
                this.countdown = 60;
                const timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(timer);
                    this.isCounting = false;
                }
                }, 1000);
                if(this.isEmailValid(this.username))
                {
                    authenticationClient.sendEmail(this.username, EmailScene.LOGIN_VERIFY_CODE).then(response => {
                    // 在这里处理 Promise 解决时返回的数据
                    // 在这里执行其他操作，根据需要处理数据
                    })
                    .catch(error => {
                        // 处理 Promise 被拒绝时的情况
                        this.openNotification('发送电子邮件时出错:', error)
                        clearInterval(timer);
                        this.isCounting = false;    
                        // 在这里执行错误处理逻辑
                    });
                }
                else{
                    console.log("手机号");
                }
            }
        },
        openNotification(title, text) {
          this.$vs.notification({
            color:'#7d33ff',
            position:'top-right',
            title: title,
            text: text
          })
        },
        isEmailValid(email) {
            // 正则表达式用来匹配邮箱地址的模式
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

            // 使用正则表达式进行匹配
            return emailPattern.test(email);
        }
    },
    mounted() {
        this.$nextTick(()=>{
            // 获取要操作的元素
            
        let login_title=document.querySelector('.login-title');
        let register_title=document.querySelector('.register-title');
        let login_box=document.querySelector('.login-box');
        let register_box=document.querySelector('.register-box');

        // 绑定标题点击事件
        login_title.addEventListener('click',()=>{
            // 判断是否收起，收起才可以点击
            if(login_box.classList.contains('slide-up')){
                register_box.classList.add('slide-up');
                login_box.classList.remove('slide-up');
            }
        })
        register_title.addEventListener('click',()=>{
            if(register_box.classList.contains('slide-up')){
                login_box.classList.add('slide-up');
                register_box.classList.remove('slide-up');
            }
        })
        })
    }
}
</script>

<style lang="scss" scoped>

.body{
    height: 100vh;
    /* 弹性布局 居中显示 */
    display: flex;
    justify-content: center;
    align-items: center;
    /* 渐变背景 */
    background: linear-gradient(to top left,#4a61af,#dc9fff,#ce456c);
    /* 溢出隐藏 */
    overflow: hidden;
}
a{
    margin-top: 13px;
    text-decoration: none;
    color: #3e7ac5;
    position: relative;
}
/* 下划线（左进右出） */
a::after{
    content: '';
    width: 100%;
    height: 1px;
    /* currentColor可以获取当前元素或父元素的color */
    background-color: currentColor;
    position: absolute;
    left: 0;
    bottom: 0;
    /* 沿x轴缩放至隐藏 */
    transform: scaleX(0);
    /* 设置变换圆点为右 */
    transform-origin: right;
    /* 设置变换时的过渡 */
    transition: transform 0.3s ease-out;
}
a:hover::after{
    /* 沿x轴缩放至显示 */
    transform: scaleX(1);
    /* 设置变换圆点为左 */
    transform-origin: left;
}
.container{
    background-color: #222;
    width: 350px;
    height: 550px;
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
}
.container::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("../assets/bg.jpg") no-repeat;
    background-size: 500px;
    background-position: left bottom;
    opacity: 0.8;
}
/* 注册区域（登录区域很多样式和注册区域的一样，故而一些统一的样式写在了一起） */
.register-box{
    width: 70%;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: 0.3s ease;
}
.register-title,
.login-title{
    color: #fff;
    font-size: 27px;
    text-align: center;
}
.register-title span,
.login-title span{
    color: rgba(0,0,0,0.4);
    display: none;
}
.register-box .input-box
{
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 50px;
    opacity: 1;
    visibility: visible;
    transition: 0.6s ease;
}
.login-box .input-box{
    position: relative;
    margin-left: 30px;
    width: 80%;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 50px;
    opacity: 1;
    visibility: visible;
    transition: 0.6s ease;
}
.login-box .input-box-yanzhegnma{
    position: absolute;
    top:43px;
    left:300px;
    margin-left: 30px;
    width: 80%;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 50px;
    opacity: 1;
    visibility: visible;
    transition: 0.6s ease;
}
.register-box input{
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-size: 12px;
    padding: 8px 0;
    text-indent: 15px;
    outline: none;
}
.login-box input{
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-size: 12px;
    padding: 8px 0;
    text-indent: 15px;
    outline: none;
}
.register-box input:last-child,
.login-box input:last-child{
    border-bottom: none;
}
.register-box input::placeholder,
.login-box input::placeholder{
    color: rgba(0,0,0,0.4);
}
.register-box button,
.login-box button{
    width: 100%;
    padding: 15px 45px;
    margin: 15px 0;
    background: rgba(0,0,0,0.4);
    border: none;
    border-radius: 10px;
    color: rgba(255,255,255,0.8);
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease;
}
.yanzhengma{
    position: absolute; 
    top: 0px; 
    width: 100px;
    padding: 0px 0px;
}
.arrow-container-left{
    position: absolute;
    top: 110px;
    left:0px;
    cursor: pointer;
    transform: scaleX(-1);
}
.arrow-container-right{
    position: absolute;
    top: 110px;
    left:260px;
    cursor: pointer;
}
.register-box button:hover,
.login-box button:hover{
    background-color: rgba(0,0,0,0.8);
}
/* 登录区域 */
.login-box{
    position: absolute;
    inset: 0;
    top: 20%;
    z-index: 2;
    background-color: #fff;
    transition: 0.3s ease;
}
.login-box::before{
    content: "";
    background-color: #fff;
    width: 200%;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
}
.login-box .center{
    width: 80%;
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-50%);
    overflow: hidden;
}
.login-title{
    color: #000;
}
.login-box .input-box-yanzhegnma,
.login-box .input-box{
    border: 1px solid rgba(0,0,0,0.1);
}
// .login-box button{
//     background-color: #75a297;
// }
/* 注册、登录区域收起 */
.login-box.slide-up{
    top: 90%;
}
.login-box.slide-up .center{
    top: 10%;
    transform: translate(-50%,0%);
}
.login-box.slide-up .login-title,
.register-box.slide-up .register-title{
    font-size: 16px;
    cursor: pointer;
}
.login-box.slide-up .login-title span,
.register-box.slide-up .register-title span{
    margin-right: 5px;
    display: inline-block;
}
.login-box.slide-up .input-box,
.login-box.slide-up .button,
.register-box.slide-up .input-box,
.register-box.slide-up .button{
    opacity: 0;
    visibility: hidden;
}
.register-box.slide-up{
    top: 6%;
    transform: translate(-50%,0%);
}

/* 背景方块 */
.bg-squares{
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}
.bg-squares li{
    width: 40px;
    height: 40px;
    background-color: rgba(255,255,255,0.15);
    position: absolute;
    bottom: -160px;
    /* 执行动画：动画名 时长 线性 无限次播放 */
    animation: square 20s linear infinite;
}
/* 为每一个方块设置不同的位置、大小、动画延迟时间、动画时长、背景色 */
.bg-squares li:nth-child(1){
    left: 10%;
}
.bg-squares li:nth-child(2){
    left: 20%;
    width: 80px;
    height: 80px;
    /* 动画延迟时间 */
    animation-delay: 2s;
    /* 动画时长 */
    animation-duration: 17s;
}
.bg-squares li:nth-child(3){
    left: 25%;
    animation-delay: 4s;
}
.bg-squares li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    background-color: rgba(255,255,255,0.25);
    animation-duration: 22s;
}
.bg-squares li:nth-child(5){
    left: 70%;
}
.bg-squares li:nth-child(6){
    left: 80%;
    width: 120px;
    height: 120px;
    background-color: rgba(255,255,255,0.2);
    animation-delay: 3s;
}
.bg-squares li:nth-child(7){
    left: 32%;
    width: 160px;
    height: 160px;
    animation-delay: 7s;
}
.bg-squares li:nth-child(8){
    left: 55%;
    width: 20px;
    height: 20px;
    animation-delay: 15s;
    animation-duration: 40s;
}
.bg-squares li:nth-child(9){
    left: 25%;
    width: 10px;
    height: 10px;
    background-color: rgba(255,255,255,0.3);
    animation-delay: 2s;
    animation-duration: 40s;
}
.bg-squares li:nth-child(10){
    left: 90%;
    width: 160px;
    height: 160px;
    animation-delay: 11s;
}

/* 定义动画 */
@keyframes square {
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-120vh) rotate(600deg);
    }
}
</style>