image<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
};
</script>

<style lang="scss">
  // Import Bulma's core
  @import "~bulma/sass/utilities/_all";
  // Import Bulma and Buefy styles
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
  html {
    background-color: #ffffff;
  }
  .body {
    font-family: 'Open Sans', sans-serif;
  }
  ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #000000;
  }

  /*滚动条轨道的颜色*/
  ::-webkit-scrollbar-track {
      background-color:  rgb(250, 250, 250);
  }

  /*滚动条的小圆球颜色*/
  ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #3f3f3f;
  }
</style>
