<template>
    <div  style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="img-radio-choose" style="width: 400%;">
            <div class="slider">
                <div class="slider-choose" :style= "{ left:picked == '1'?'0%':picked == '2'?'33.3%' : '66.6%'}"></div>
                <div class="slider-tab" @click = "picked = '1'" :style="{color:picked == '1'?'#141414' : '#eee'}"><p>1:1</p></div>
                <div class="slider-tab" @click = "picked = '2'" :style="{color:picked == '2'?'#141414' : '#eee'}"><p>4:3</p></div>
                <div class="slider-tab" @click = "picked = '3'" :style="{color:picked == '3'?'#141414' : '#eee'}"><p>16:9</p></div>
            </div>
        </div>
    </div>
</template>

<script>
import { watch } from 'vue'

export default{
    name: 'PPlugin',
    data() {
        return {
            picked: '1',
            style: null,
        }
    },
    methods: {
        // 选择图片比例
        chooseRatio() {
            this.$emit('chooseRatio', this.picked)
        },
        chooseStyle(style){
            if(this.style == style){
                this.style = null
                this.$emit('chooseStyle', null)
                return
            }
            this.style = style
            this.$emit('chooseStyle', style)
        }
    },
    watch: {
        picked: function (val) {
            this.$emit('chooseRatio', val)
        }
    }
}
</script>

<style lang="scss" scoped>
.slider {
    position: relative;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    height: 25px;
    top: 9.5%;
    background-color: #2C2C2C;
    border-radius: 10px;
  }
  .slider-choose {
    position: absolute;
    width: 33%;
    height: 100%;
    background-color: #FAFF00;
    box-shadow: 0 0 5px 2px #fbff0083;
    z-index: 10;
    border-radius: 10px;
    transition: left 0.3s ease;
  }

  .slider-tab{
    color:#eee;
    width: 33%;
    justify-items: center;
    align-items: center;
    text-align: center;
    z-index: 11;
    cursor: pointer;
  }
  .lora-choose
{
    // border: 2px solid #a1a1a1;
    border-radius: 10px;
    border: #868686 1px solid;
    width: 90vw;
    background-color: #424242;
}
.img-radio-choose{
    position: relative;
    display: flow-root;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 15%;
    margin: 1%;
}
::v-deep .vs-card{
    max-width: 9vw !important;
    min-width: 288px;
}
::v-deep .vs-card__group .vs-card__group-cards .vs-card-content {
    max-width: 9vw;
    min-width: 288px;
}
</style>