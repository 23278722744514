<template>
  <div class="home">
    <div class = "helper">
        <h1 style="color:rgb(255, 255, 255)">Arti售后助手</h1>
        <img  src="../assets/helper.jpeg" height="20">
    </div>
    <PHeader></PHeader>
    <PGenerate></PGenerate>
    <Pins></Pins>
  </div>
</template>

<script>
import PHeader from '../components/PHeader.vue';
import PGenerate from '../components/PGenerate.vue';
import Pins from '../components/Pins.vue';

export default {
  name: 'Home',
  components: {
    PHeader,
    PGenerate,
    Pins,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
body{
  position: absolute;
}
.home{
  background: linear-gradient(to bottom,#363636,#000000de);
}
.helper{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  top: 45%;
  right: 20px;
  width: 10vw;
  max-width: 120px;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  padding: 5px;
  color: rgb(233, 156, 67);
  font-size: 1vh;
}
</style>
