// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/loader.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".grid-item[data-v-3e57653a],.grid-sizer[data-v-3e57653a]{width:240px}.grid-item[data-v-3e57653a]{margin-bottom:15px}.gutter-sizer[data-v-3e57653a]{width:15px}.board-card .card-image>img[data-v-3e57653a]{min-width:240px;background-color:#fff;border-radius:3px 3px 0 0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat}.board-footer[data-v-3e57653a]{position:relative;top:-6px;background-color:#fff;border-radius:0 0 3px 3px;-webkit-box-shadow:0 1px 0 #bbb;box-shadow:0 1px 0 #bbb;font-weight:700}.board-footer .description[data-v-3e57653a]{font-size:12px;color:#999;font-weight:400;padding-left:10px;padding-bottom:5px;overflow:hidden;text-overflow:ellipsis}.board-footer .board-info[data-v-3e57653a]{padding:10px;color:#333}.board-footer .num-pins[data-v-3e57653a]{font-size:.8rem;color:#333}@media screen and (min-width:288px){#boards-container[data-v-3e57653a]{max-width:360px}}@media screen and (min-width:543px){#boards-container[data-v-3e57653a]{max-width:495px}}@media screen and (min-width:798px){#boards-container[data-v-3e57653a]{max-width:750px}}@media screen and (min-width:1053px){#boards-container[data-v-3e57653a]{max-width:1005px}}@media screen and (min-width:1308px){#boards-container[data-v-3e57653a]{max-width:1260px}}@media screen and (min-width:1563px){#boards-container[data-v-3e57653a]{max-width:1515px}}@media screen and (min-width:1818px){#boards-container[data-v-3e57653a]{max-width:1770px}}@media screen and (min-width:2073px){#boards-container[data-v-3e57653a]{max-width:2025px}}@media screen and (min-width:2328px){#boards-container[data-v-3e57653a]{max-width:2280px}}@media screen and (min-width:2583px){#boards-container[data-v-3e57653a]{max-width:2535px}}@media screen and (min-width:2838px){#boards-container[data-v-3e57653a]{max-width:2790px}}", ""]);
// Exports
module.exports = exports;
