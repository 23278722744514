import axios from 'axios';
import storage from './utils/storage';

const API_PREFIX = '/api/v2/';

const Board = {
  create(name, private_ = false, sub_id) {
    const url = `${API_PREFIX}boards/`;
    const data = { name, private: private_ , subid: sub_id};
    return new Promise(
      (resolve, reject) => {
        axios.post(url, data).then(
          (resp) => {
            if (resp.status !== 201) {
              reject(resp);
            }
            resolve(resp.data);
          },
          (error) => {
            reject(error.response);
          },
        );
      },
    );
  },
  get(boardId) {
    const url = `${API_PREFIX}boards/${boardId}/`;
    return axios.get(url);
  },
  fetchFullList(username) {
    const url = `${API_PREFIX}boards-auto-complete/?sub_id=${username}`;
    return axios.get(url);
  },
  fetchSiteFullList() {
    const url = `${API_PREFIX}boards-auto-complete/`;
    return axios.get(url);
  },
  fetchListWhichContains(text, offset = 0, limit = 50) {
    const prefix = `${API_PREFIX}boards/?search=${text}`;
    const url = `${prefix}&offset=${offset}&limit=${limit}`;
    return axios.get(url);
  },
  saveChanges(boardId, fieldsForm) {
    const url = `${API_PREFIX}boards/${boardId}/`;
    return axios.patch(
      url,
      fieldsForm,
    );
  },
  addToBoard(boardId, pinIds) {
    const url = `${API_PREFIX}boards/${boardId}/`;
    return axios.patch(
      url,
      { pins_to_add: pinIds },
    );
  },
  removeFromBoard(boardId, pinIds) {
    const url = `${API_PREFIX}boards/${boardId}/`;
    return axios.patch(
      url,
      { pins_to_remove: pinIds },
    );
  },
  delete(boardId) {
    const url = `${API_PREFIX}boards/${boardId}/`;
    console.log(url)
    return axios.delete(url);
  },
};

const Pin = {
  create(jsonData) {
    const url = `${API_PREFIX}pins/`;
    return axios.post(
      url,
      jsonData,
    );
  },
  createFromURL(jsonData) {
    return this.create(jsonData);
  },
  createFromUploaded(jsonData) {
    return this.create(jsonData);
  },
  uploadImage(fileObject) {
    const url = `${API_PREFIX}images/`;
    const data = new FormData();
    data.append('image', fileObject);
    return axios.post(
      url,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  deleteById(pinId, sub_id) {
    const url = `${API_PREFIX}pins/${pinId}/`;
    const queryArgs = {
      subid: sub_id,
    };
    return axios.delete(
      url,
      {params: queryArgs},
    );
  },
  updateById(pinId, data, sub_id) {
    const queryArgs = {
      subid: sub_id,
    };
    const url = `${API_PREFIX}pins/${pinId}/`+'?sub_id='+sub_id;
    return axios.patch(
      url,
      data,
      {params: queryArgs},
    );
  },
};

const Canvas = {
  create(user_id, jsonData, image_id, project_id) {
    const url =  `${API_PREFIX}profile/canvas/`
    const queryArgs = {
      user: user_id,
      json_data: jsonData,
      image_by_id: image_id,
      project_id: project_id,
    }
    return axios.post(
      url,
      queryArgs,
    );
  }
}

function fetchCanvas(user_id) {
  const url =  `${API_PREFIX}canvas/`;
  const queryArgs = {
    user: user_id,
  }
  return axios.get(
    url,
    { params: queryArgs }
  )
}

function fetchPins(offset, tagFilter, userFilter, boardFilter, sub_id) {
  const url = `${API_PREFIX}pins/`;
  const queryArgs = {
    format: 'json',
    ordering: '-id',
    limit: 50,
    offset,
    userfilter_id: userFilter? userFilter: null,
    sub_id: sub_id,
  };
  if (tagFilter) queryArgs.tags__name = tagFilter;
  if (boardFilter) queryArgs.pins__id = boardFilter;
  return axios.get(
    url,
    { params: queryArgs ,
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
  );
}

function fetchPin(pinId, sub_id) {
  const url = `${API_PREFIX}pins/${pinId}`;
  const queryArgs = {
    sub_id: sub_id,
  };
  return new Promise(
    (resolve, reject) => {
      const p = axios.get(
        url,
        { params: queryArgs },
      );
      p.then(
        (resp) => {
          const response = {
            data: { results: [resp.data], next: null },
          };
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    },
  );
}

function fetchBoardForUser(sub_id, username, offset = 0, limit = 50) {
  const prefix = `${API_PREFIX}boards/`;
  const url = `${prefix}?&offset=${offset}&limit=${limit}&sub_id=${sub_id}`;
  return axios.get(url);
}

const User = {
  signUp(username, userid, invited_code) {
    const url = `${API_PREFIX}profile/users/`;
    return new Promise(
      (resolve, reject) => {
        const p = axios.post(
          url,
          {
            user_name: username,
            user_id: userid,
            invited_code: invited_code,
          },
        );
        p.then(
          (resp) => {
            if (resp.status !== 201) {
              reject(resp);
            }
            resolve(resp.data);
          },
          (error) => {
            console.log('Failed to sign up due to unexpected error:', error);
            reject(error.response);
          },
        );
      },
    );
  },
  // logIn(username, password) {
  //   const url = `${API_PREFIX}profile/login/`;
  //   return new Promise(
  //     (resolve, reject) => {
  //       const p = axios.post(
  //         url,
  //         {
  //           username,
  //           password,
  //         },
  //       );
  //       p.then(
  //         (resp) => {
  //           if (resp.status !== 200) {
  //             reject(resp);
  //           }
  //           resolve(resp.data);
  //         },
  //         (error) => {
  //           console.log('Failed to log in due to unexpected error:', error);
  //           reject(error.response);
  //         },
  //       );
  //     },
  //   );
  // },
  // logOut() {
  //   const self = this;
  //   return new Promise(
  //     (resolve) => {
  //       axios.get('/api-auth/logout/').then(
  //         () => {
  //           storage.set(self.storageKey, null, 1);
  //           resolve();
  //         },
  //       );
  //     },
  //   );
  // },
  fetchUserInfoByID(user_id) {
    /* returns null if user not logged in */
    const url = `${API_PREFIX}profile/users/?user_id=${user_id}`;
    return new Promise(
      (resolve) => {
        axios.get(url).then(
          (resp) => {
            const users = resp.data;
            if (users.length === 0) {
              return resolve(null);
            }
            return resolve(users.results[0]);
          },
        );
      },
    );
  },
  fetchUserInfo(user_id) {
    /* returns null if user not logged in */
    const self = this;
    if (!force) {
      const userInfo = storage.get(self.storageKey);
      if (userInfo !== null) {
        return new Promise(
          resolve => resolve(userInfo),
        );
      }
    }
    const url = `${API_PREFIX}profile/users/`;
    return new Promise(
      (resolve) => {
        axios.get(url).then(
          (resp) => {
            const users = resp.data;
            if (users.length === 0) {
              return resolve(null);
            }
            const value = users[0];
            storage.set(self.storageKey, value, 60 * 5 * 1000);
            return resolve(users[0]);
          },
        );
      },
    );
  },
};

const Tag = {
  fetchList() {
    const url = `${API_PREFIX}tags-auto-complete/`;
    return axios.get(url);
  },
};

export default {
  Tag,
  Pin,
  Board,
  fetchPin,
  fetchPins,
  fetchBoardForUser,
  User,
  Canvas,
};
