import Buefy from 'buefy';
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './registerServiceWorker';
import Vuesax from 'vuesax'
import VueClipboard from 'vue-clipboard2'
import axios from 'axios';
import draggable from 'vuedraggable';
import { VueMasonryPlugin } from 'vue-masonry';
import VueI18n from 'vue-i18n';
import localeUtils from './components/utils/i18n';
import setUpAxiosCsrfConfig from './components/utils/csrf';
// import fabric from 'fabric'
import App from './App.vue';
import router from './router';



import 'vuesax/dist/vuesax.css' //Vuesax styles
import '@/assets/icon.css'
import '@/assets/style.css'
import { GuardPlugin } from "@authing/guard-vue2";
import "@authing/guard-vue2/dist/esm/guard.min.css";


Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
// Vue.use(fabric);
Vue.use(Vuesax);
Vue.use(Buefy);
Vue.use(VueMasonryPlugin);
Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.use(GuardPlugin, {
  appId: "66c005588d672df76c277fff",
  mode: 'modal',
});

Vue.component('draggable', draggable);
setUpAxiosCsrfConfig();


const i18n = new VueI18n({
  locale: localStorage.getItem('localeCode') || navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages: localeUtils.messages,
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');

router.beforeEach((to, from, next) => {
	if (to.meta.content) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
		document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
		document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
		meta.content = to.meta.content;
		head[0].appendChild(meta)
	}
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next()
});

// (() => {
//     function block() {
//         if (window.outerHeight - window.innerHeight > 200 ||window.outerWidth - window.innerWidth > 200) {
//             document.body.innerHTML ="检测到非法调试,请关闭后刷新重试!";
//         }
//         setInterval(() => {
//             (function () {
//                 return false;
//             }
//                 ["constructor"]("debugger")
//                 ["call"]());
//         }, 50);
//     }
//     try {
//         block();
//     } catch (err) {}
// })();