<template>
    <div class="Pgenerator" v-if="user.loggedIn">
        <!-- <div class="image-prompt-form-header">
            <div class = "description">
                <div class="body-small">
                    {{ $t("InputDescription") }}
                </div>
            </div>
        </div> -->
        <!-- <button @click="test">
          <span>
            test
          </span>
        </button> -->
        <div class="input-container"> 
            <input type="text" v-model="inputText" class="image-prompt-input" :placeholder="$t('descriptionExample')" maxlength="400" >
            <button tabindex="0" @click="sendRequest" :disabled="!inputText || loading" :class="buttonClass" aria-label="Submit prompt">
                <vs-tooltip>
                  <span class="btn-label-wrap">
                    <span class="btn-label-inner">{{$t("generate")}}</span>
                </span>
                <template #tooltip>
                  <!-- {{useControl?'消耗4点' : '消耗2点'}} -->
                  {{relax? '消耗1点':'消耗4点'}}
                </template>
                </vs-tooltip>
            </button>
            <!-- <vs-tooltip style="margin-left: 10px;">
              <vs-checkbox v-model="sdxl">
                启用sdxl
              </vs-checkbox>
              <template #tooltip>
                4点/次
              </template>
            </vs-tooltip> -->
        </div>
        <div style="display: flex; border: 1px solid #FAFF00; border-radius: 10px; padding: 10px;">
                <vs-tooltip style="margin-right: 10px;">
                    <vs-checkbox v-model="relax" style="color: #ffffff;" color="#FAFF00">
                    启用relaxe
                    </vs-checkbox>
                    <template #tooltip>
                    1点/次
                    </template>
                </vs-tooltip>
                <vs-tooltip>
                    <vs-checkbox v-model="expand" style="color: #ffffff;" color="#FAFF00">
                    启用AI动态提示词改写
                    </vs-checkbox>
                    <template #tooltip>
                    限时免费
                    </template>
                </vs-tooltip>
        </div>
        <div class="plugin">
          <PPlugin 
          @chooseRatio="HandleImgRadio" @chooseStyle="HandleStyleTemplate" v-show="usePlugin" ref="pluginEdit"></PPlugin>
        </div>
        <br>
        <div class = "box-loading" ref="loadconner" v-show="loading"></div>
        <br>
        <div class = "box-loading" ref="likeload" v-show="like_loading"></div>
        <div class="image-container" v-show="images.length > 0 && like_loading==false">
            <div class="image-wrapper" v-for="(imageData, index) in images" :key="index"
            @mouseover="showButton(index)" @mouseleave="hideButton(index)">
                <img :src="imageData" class="image">
                <button class="upload-button" v-if="buttonindex[index]" @click="quickCreatePin(index)">
                    <span class="btn-label-wrap">
                        <span class="like-button">{{$t("upload")}}</span>
                    </span>
                </button>
            </div>
            <!-- <img v-for="(imageData, index) in images" :key="index" :src="getImageSrc(imageData)" class="image"/> -->
        </div>
        <!-- </form> -->
    </div>
</template>more


<script>
import API from './api';
import { EventBus } from './event-bus';
import ModelForm from './utils/ModelForm';
import bus from './utils/bus';
// import FileUpload from './Pcontrol.vue';
import DrawingBoard from './drawingBoard.vue';
import PPlugin from './PPlugin.vue';

const fields = ['url', 'referer', 'description', 'tags', 'private', 'avatar', 'subid'];

export default {
  name: 'Pgenerator',
  data() {
    const pinModel = ModelForm.fromFields(fields);
    pinModel.form.tags.value = [];
    return {
      showedit: -1,
      inputText: '',
      moreindex: -1,
      images: [],
      lastseed: -1,
      buttonindex: [false, false, false, false],
      loading: false,
      loading_component:null,
      like_loading: false,
      like_loading_component:null,
      id: -1,
      pinModel,
      controlImg: {
        type: String,
        default: null,
      },
      useControl: false,
      usePlugin: true,
      ControlMode: [0],
      ControlStrength: [1.0],
      option1: 0,
      option2: 1,
      option3: 2,
      soption1: 1.2,
      soption2: 1.4,
      soption3: 1.6,
      user: {
        loggedIn: false,
        meta: {},
      },
      sdxl:false,
      vip_info: {
        vip: false,
        count: 0
      },
      imgRadio: '1',
      styletempla: null,
      relax:false,
      expand:true
    };
  },
  components: {
    // FileUpload,
    DrawingBoard,
    PPlugin
  },
  created() {
    this.placeholder = document.createElement('div');
    this.placeholder.classList.add('placeholder');
    this.placeholder.style.display = 'none';
  },
  computed: {
    buttonClass() {
      return this.inputText ? 'Gbutton' : 'Gbutton_';
    },
    previewImage() {
      if (this.previewExists()) {
        return this.previewImageURL;
      }
      return null;
    },
  },
  watch: {
    ControlMode(newVal) {
      if (newVal.length > 1) {
        this.ControlMode = [newVal[newVal.length - 1]];
      } else if (newVal.length === 0) {
        this.ControlMode = [0];
      }
      // console.log(this.ControlMode);
    },
    ControlStrength(newVal) {
      if (newVal.length > 1) {
        this.ControlStrength = [newVal[newVal.length - 1]];
      } else if (newVal.length === 0) {
        this.ControlStrength = [1.0];
      }
      // console.log(this.ControlStrength);
    },
    // inputText(value, oldValue) {
    //   if (value === '' && oldValue !== '') {
    //     this.inputText = oldValue;
    //   }
    // },
  },
  methods: {
    previewExists() {
      return this.previewImageURL !== null && this.previewImageURL !== '';
    },
    sendRequest() {
      if(this.loading_component == null)
      {
        this.loading_component = this.$vs.loading({
          target: this.$refs['loadconner'],
          text: "Generating",
          type: "circles",
          color: '#FAFF00'
        })
      }
      if(this.like_loading_component == null)
      {
        this.like_loading_component = this.$vs.loading({
          target: this.$refs['likeload'],
          text: "保存中",
          type: "circles",
          color: '#FAFF00'
        })
      }

      var trigger_id = this.generateUniqueRandomNumber();
      this.images = [];
      this.loading = true;
      const w_dict={
        '1':1024,
        '2':960,
        '3':720,
        '4':1280,
        '5':720,
      }
      const h_dict={
        '1':1024,
        '2':720,
        '3':960,
        '4':720,
        '5':1280,
      }
      const params = {
        prompt: `${this.inputText}`,
        expand: this.expand,
        relax: this.relax,
        subid: this.user.meta.id,
        img_ratio: this.imgRadio
      };
      const params_xl = {
        prompt: `${this.inputText}`,
        batch_size: 2,
        width:1024,
        height:1024,
        sampler_name:'Euler',
        steps: 20,
        trigger_id: trigger_id,
        subid: this.user.meta.id,
        save_images: true,
        // negative_prompt: '',
      };
      // console.log(port)
      if(this.useControl){
        this.$axios.post('/api/v2/queue/t2ixl/',paramsControl).then((data)=>{
          // console.log(data);
          if(data.data.success){
            const interval = setInterval(async () => {
            const resultResponse = await this.$axios.post('/api/v2/queue/getret/', checkparam);
            // console.log(resultResponse);
            if (resultResponse.data.images) {
              // 结果已生成，执行你需要的操作
              clearInterval(interval);
              // 处理结果的逻辑
              this.images = resultResponse.data.images.slice(1, 5).map((imageData) => {
                const binary = Buffer.from(imageData, 'base64').toString('binary');
                const array = new Uint8Array(binary.length);
                console.log(binary.length);
                for (let i = 0; i < binary.length; i += 1) {
                  array[i] = binary.charCodeAt(i);
                }
                return array;
              });
              this.loading = false;
            }
          }, this.relax? 15000: 30000); // 每15秒发送一次请求，可以根据需要调整间隔时间
          EventBus.$emit('updateHistory');
          }
          else{
            this.openNotification('生成失败',data.data.message);
            this.loading = false;
          }
        }
        ).catch((error)=>{
          this.openNotification('生成失败',error.response.data.message);
          this.loading = false;
        })
      }else{
        this.$axios.post('/api/v2/queue/t2imj/',params).then((data)=>{
          // console.log(data);
          this.inputText = data.data.prompt;
          const checkparam ={
            relax: this.relax,
            task_id: data.data.result,
            subid: this.user.meta.id,
          }
          if(data.data.code == "1"){
            const interval = setInterval(async () => {
            const resultResponse = await this.$axios.post('/api/v2/queue/mjstatus/', checkparam);
            // console.log(resultResponse);
            if (resultResponse.data.success) {
              // 结果已生成，执行你需要的操作
              clearInterval(interval);
              // 处理结果的逻辑
              this.images = resultResponse.data.urls;
              this.loading = false;
            }
          }, this.relax? 30000 : 15000); // 每15秒发送一次请求，可以根据需要调整间隔时间
          EventBus.$emit('updateHistory');
          }
          else{
            this.openNotification('生成失败',data.message);
            this.loading = false;
          }
        }
        ).catch((error)=>{
          this.openNotification('生成失败',error.response.data.message);
          this.loading = false;
        })
      }

      // this.$axios.post('/api/v2/queue/t2i/', this.useControl ? paramsControl : params).then((data) => {
      //   console.log(data);
      //   this.images = data.data.images.map((imageData) => {
      //     const binary = Buffer.from(imageData, 'base64').toString('binary');
      //     const array = new Uint8Array(binary.length);
      //     for (let i = 0; i < binary.length; i += 1) {
      //       array[i] = binary.charCodeAt(i);
      //     }
      //     return array;
      //   });
      //   // 从响应中提取图片并保存到 images 数组中
      //   console.log('请求发送成功', this.images);
      //   this.loading = false;
      //   loading.close()
      // }).catch((error) => {
      //   this.openNotification('生成失败',error.response.data.message)
      //   this.loading = false;
      // });
    },
    generateUniqueRandomNumber() {
      let randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
      return randomNumber;
    },

    generateRandomInt32() {
      const min = 0;
      const max = (2 ** 31) - 1;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getImageSrc(imageData) {
      // 将图片的二进制数据转换为 Data URL
      const blob = new Blob([imageData], { type: 'image/png' });
      const url = URL.createObjectURL(blob);
      return url;
    },
    quickCreatePin(index) {

      this.like_loading = true;
      this.pinModel.form.url.value = this.images[index];
      this.pinModel.form.referer.value = 'ARTI';
      this.pinModel.form.description.value = `${this.inputText}`;
      this.pinModel.form.tags.value = [];
      this.pinModel.form.private.value = this.vip_info.vip? true: false;
      this.pinModel.form.avatar.value = this.user.meta.photo;
      this.pinModel.form.subid.value = this.user.meta.id;

      API.Pin.createFromURL(this.pinModel.asDataByFields(
        ['referer', 'description', 'tags', 'private', 'avatar','subid', "url"],
      )).then(
        () => {
          this.like_loading = false;
          bus.bus.$emit(bus.events.refreshPin);
        },
      )
        .catch((error) => {
          console.log('Cannot create pin:', error);
        });
    },
    HandleControlImgUrl(url) {
      this.controlImg = url;
    },
    HandleImgRadio(value) {
      this.imgRadio = value;
      console.log(this.imgRadio);
    },
    HandleStyleTemplate(value){
      this.styletempla = value;
    },
    showButton(index) {
      this.buttonindex[index] = true;
      this.$forceUpdate();
    },
    showMoreButton(index) {
      this.moreindex = index;
      this.$forceUpdate();
    },
    hideButton(index) {
      this.buttonindex[index] = false;
      this.$forceUpdate();
    },
    hideMoreButton() {
      this.moreindex = -1;
      this.$forceUpdate();
    },
    addweight(index) {
      this.segPromptWeight[index] = (parseFloat(this.segPromptWeight[index]) + 0.05).toFixed(2);
      // console.log(this.segPromptWeight[index]);
    },
    lessweight(index) {
      this.segPromptWeight[index] = (parseFloat(this.segPromptWeight[index]) - 0.05).toFixed(2);
      // console.log(this.segPromptWeight[index]);
    },
    onDragEnd(event) {
      const newOrder = event.newIndex;
      const movedItem = this.segPromptWeight.splice(event.oldIndex, 1)[0];
      this.segPromptWeight.splice(newOrder, 0, movedItem);
    },
    closeedit() {
      this.showedit = -1;
    },
    openNotification(title, text) {
      this.$vs.notification({
        color:'danger',
        position:'top-right',
        title: title,
        text: text
      })
    }
  },
  mounted() {
    EventBus.$on('user-loaded', (user) => {
      this.user = user;
    });
    EventBus.$on('vip-info-loaded', (vipInfo) => {
      this.vip_info = vipInfo;
    });
  },
};
</script>

<style lang="scss" scoped>
@import './utils/fonts.scss';
@media (max-width: 1400px) {
    html {
        font-size: 20px;  /* 在小屏幕上，1rem将等于14px */
    }
}

@media (max-width: 1200px) {
    html {
        font-size: 18px;  /* 在小屏幕上，1rem将等于14px */
    }
}

@media (max-width: 600px) {
    html {
        font-size: 10px;  /* 在小屏幕上，1rem将等于14px */
    }
}


.Pgenerator{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width:100vw;
}
.description{
    font-size: 1.3rem;
}
.input-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 40px;
    width: 51%;
    height: 4vh;
    min-height: 55px;
}
input {
    border: none;
    padding: 16px 20px;
    font-size: 1.2rem;
    background-color: #f7f7f7;
    border-radius: 15px;
    border-radius: 15px;
    width: 100%;
    height: 110%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.589);
}
.Gbutton_ {
    position: absolute;
    right: 0;
    width: 12rem;
    height: 110%;
    border: none;
    background-color: #FAFF00;
    border-radius: 15px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 5px 2px #fbff0083;
    transition: transform 0.3s;
    font-size: 1.2rem;
}

.Gbutton{
  position: absolute;
  right: 0;
  width: 12rem;
  height: 110%;
  border: none;
  background-color: #FAFF00;
  border-radius: 15px;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 5px 2px #fbff0083;
  transition: transform 0.3s;
  font-size: 1.2rem;
}
.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.image-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
}
.image-wrapper:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  z-index: 1;
  animation: fade-in 0.5s ease-in-out forwards;
}

.image-wrapper img {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
    margin: 0 16px;
    box-shadow: 0 10px 20px rgba(41, 41, 41, 0.1);
    animation: fade-in 0.5s ease-in-out forwards;
}

.upload-button {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: auto;
    aspect-ratio: 1 / 1;
    border: none;
//   transform: translate(-50%, -50%);
    padding: 0.5em;
//   background-color: rgb(190, 190, 190);
    color: white;
    z-index: 2;
    border-radius: 50%;
    opacity: 0.7;
    cursor: pointer;
    animation: fade-in 0.5s ease-in-out forwards;
}
.like-button
{
  font-size: 1.2rem;
    color: rgba(0, 0, 0, 1);
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.image-prompt-form-header {
    margin-top: 40px;
}




.control{
  width: 20px;
  height: 20px;
}
.control-mode
{
  margin-left: 10px;
  margin-right: 10px;
  width: 15px;
  height: 15px;
}
.btn-label-inner{
    font-size: 1.1rem;
}
.Gbutton:hover{
    border: #000000 solid 2px;
    transform: scale(1.1);
}
.loading-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.box-loading
{
  width: 120px;
  height: 120px;
  position: relative;
  margin: 5px;
  border-radius: 20px;
  overflow: hidden;

  >>>.vs-loading{
    padding: 0px;
  }
}
</style>
<style lang="scss">
#divContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    // align-items: center;
}
.seg-prompt{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.seg-prompt-text{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200%;
    background-color: #f0f0f0;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 0.8rem;
}
.more{
    width: 100%;
    height: 100%;
    position: absolute;
    gap: 5%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    border-radius: 10px;
}
.weight-button{
  width: 40%;
  height: auto;
  font-size: 0.5rem;
  border-radius: 10px;
}
.text-input-prompt{
    width: 16ch;
    position: absolute;
    left: 8%;
    gap: 5%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    border-radius: 10px;
    position: absolute;
    font-size: 1.1rem;
    z-index: 4;
}
.slide-enter-active, .slide-leave-active {
    transition: all 0.3s ease-out;
}
.slide-enter, .slide-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
.plugin{
  margin-top: 20px;
}
</style>
