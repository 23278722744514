import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Mains.vue';
import Home from '../views/Home.vue';
import Pins4Tag from '../views/Pins4Tag.vue';
import Pins4User from '../views/Pins4User.vue';
import Pins4Board from '../views/Pins4Board.vue';
import Pins4Id from '../views/Pins4Id.vue';
import Boards4User from '../views/Boards4User.vue';
import Profile4User from '../views/Profile4User.vue';
import PinCreate from '../views/PinCreate.vue';
import Search from '../views/Search.vue';
import PageNotFound from '../views/PageNotFound.vue';
import DrawingBoardView from '../views/DrawingBoardView.vue';
import Privacy from '../views/Privacy.vue';
import Login from '../views/Login.vue';
import Projects from '../views/Projects.vue'
// import TbDesignView from '../views/TbDesignView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Main,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Arti-id - 登录',
      content: {
        keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
        description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
      }
    }
  },
  {
    path: '/home',
    name: 'homewindow',
    component: Home,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/pins/tags/:tag',
    name: 'tag',
    component: Pins4Tag,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/pins/users/:user',
    name: 'user',
    component: Pins4User,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/pins/boards/:board',
    name: 'board',
    component: Pins4Board,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/pins/:pinId',
    name: 'pin',
    component: Pins4Id,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/boards/users/:username',
    name: 'boards4user',
    component: Boards4User,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/profile/:username',
    name: 'profile4user',
    component: Profile4User,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/pin-creation/from-url',
    name: 'pin-creation-from-url',
    component: PinCreate,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/sketch1',
    name: 'sketch',
    component: DrawingBoardView,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: {
			title: 'Arti-id',
			content: {
				keywords: 'arti、Arti、AI设计、stable diffusion、设计服务',
				description: 'Arti是一款面向设计的专业AI辅助平台，支持中文，集成了数十种不同风格的插件，具有【一键草图渲染生成】，【局部重绘】，【线稿提取】，【AI商品图生成】等多种功能，无需复杂提示词'
			}
		}
  }
  // {
  //   path: '/tbdesign',
  //   name: 'tbdesign',
  //   component: TbDesignView,
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});


 
// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`;
 
//     if (route.path !== '*') {
//       array.push(path);
//     }
 
//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`));
//     }
 
//     return array;
//   }, []);
// }
 
 
// // getRoutesList(router.options.routes, 'https://zigamiklic.com');
// function getRoutesXML() {
//   const list = getRoutesList(router.options.routes, 'https://www.arti-id.com/')
//     .map(route => `<url><loc>${route}</loc></url>`)
//     .join('\r\n');
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }
 
// console.log(getRoutesXML())

export default router;
