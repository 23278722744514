<template>
  <div class="pins-for-board">
    <PHeader></PHeader>
    <Pins :pin-filters="filters" :currentBoard="cboard"></Pins>
  </div>
</template>

<script>
import PHeader from '../components/PHeader.vue';
import Pins from '../components/Pins.vue';

export default {
  name: 'Pins4Board',
  data() {
    return {
      filters: { boardFilter: null },
      cboard:null,
    };
  },
  components: {
    PHeader,
    Pins,
  },
  beforeRouteUpdate(to, from, next) {
    this.filters = { boardFilter: to.params.board.id };
    next();
  },
  created() {
    this.initializeBoard();
  },
  methods: {
    initializeBoard() {
      this.filters = { boardFilter: this.$route.params.board.id };
      this.cboard = this.$route.params.board;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pins-for-board{
  background: linear-gradient(to bottom,#363636,#000000de);
}
.home{
  background: linear-gradient(to bottom,#363636,#000000de);
}
</style>
