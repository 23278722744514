<!-- <template>
    <div class="board-modal">
        <div class="modal-card" style="width: 1100px;">
          <header class="modal-card-head" style="background-color: rgb(43, 70, 221);">
            <p class="modal-card-title">Pro会员购买</p>
          </header>
          <section class="modal-card-body">
            <div class = "choose-card">
                <div class="choose-plan"  @click="changePlan('1')" :style="{border: currentPlan === '1'? '2px solid rgb(43, 70, 221)' : '1px solid #ccc'}">
                  <div class="_4nz5V">
                    <div class="RX8x4">
                      <span>
                        可生成
                        <b>12,400</b>
                        张图
                      </span>
                    </div>
                  <div class="swI7t">1个月</div> 
                  <div class="bvSP7">
                    <span class="bI+s5"><b>150</b></span>点<span class="cGUJU">/</span>天
                  </div>
                  <div class="fVfhV"><div class="EjJCf">
                    赠送<span class="bI+s5"><b>50</b></span>点<span class="cGUJU">/</span>天
                  </div></div>
                  <div class="L0UMK">AI创作初学者</div>
                  <div class="ZWAGJ">
                    <span class="bI+s5">￥</span> 
                    <span class="cGUJU"><b>29.9</b></span> 
                    <span class="mDdXi">/月</span>
                  </div> 
                  <div class="QSOFq"><span class="BIn33">原价：</span><span class="bI+s5"><span class="Xsv5A">￥</span><span class="uHTTU"></span><span>99.9</span></span></div> 
                  <div class="Q5R0i"><span>限时特惠 3.3 折</span></div> <ul class="axYAm"><li><b>6200</b>点</li><li><b>0.0033</b>元/点</li> <li>无限存储*</li> <li>无限下载/无水印</li> <li>默认开启私密状态</li> <li>可开发票</li></ul></div>
                  <div class="overlay" v-if="currentPlan !='1'"></div>
                </div>
                <div class="choose-plan" @click="changePlan('2')" :style="{border: currentPlan === '2'? '2px solid rgb(43, 70, 221)' : '1px solid #ccc'}">
                  <div class="_4nz5V">
                    <div class="RX8x4">
                      <span>
                        可生成
                        <b>46,500</b>
                        张图
                      </span>
                    </div>
                  <div class="swI7t">3个月</div> 
                  <div class="bvSP7">
                    <span class="bI+s5"><b>150</b></span>点<span class="cGUJU">/</span>天
                  </div>
                  <div class="fVfhV"><div class="EjJCf">
                    赠送<span class="bI+s5"><b>100</b></span>点<span class="cGUJU">/</span>天
                  </div></div>
                  <div class="L0UMK">AI创作爱好者</div>
                  <div class="ZWAGJ">
                    <span class="bI+s5">￥</span> 
                    <span class="cGUJU"><b>99.9</b></span> 
                    <span class="mDdXi">/3月</span>
                  </div> 
                  <div class="QSOFq"><span class="BIn33">原价：</span><span class="bI+s5"><span class="Xsv5A">￥</span><span class="uHTTU"></span><span>213</span></span></div> 
                  <div class="Q5R0i"><span>限时特惠 2.8 折</span></div> <ul class="axYAm"><li><b>23250</b>点</li><li><b>0.0025</b>元/点</li> <li>无限存储*</li> <li>无限下载/无水印</li> <li>默认开启私密状态</li> <li>可开发票</li> </ul></div>
                  <div class="overlay" v-if="currentPlan !='2'"></div>
                </div>
                <div class="choose-plan" @click="changePlan('3')" :style="{border: currentPlan === '3'? '2px solid rgb(43, 70, 221)' : '1px solid #ccc'}">
                  <div class="_4nz5V">
                    <div class="RX8x4">
                      <span>
                        可生成
                        <b>255,500</b>
                        张图
                      </span>
                    </div>
                  <div class="swI7t">12个月</div> 
                  <div class="bvSP7">
                    <span class="bI+s5"><b>150</b></span>点<span class="cGUJU">/</span>天
                  </div>
                  <div class="fVfhV"><div class="EjJCf">
                    赠送<span class="bI+s5"><b>200</b></span>点<span class="cGUJU">/</span>天
                  </div></div>
                  <div class="L0UMK">AI专业工作者</div>
                  <div class="ZWAGJ">
                    <span class="bI+s5">￥</span> 
                    <span class="cGUJU"><b>635</b></span> 
                    <span class="mDdXi">/12月</span>
                  </div> 
                  <div class="QSOFq"><span class="BIn33">原价：</span><span class="bI+s5"><span class="Xsv5A">￥</span><span class="uHTTU"></span><span>1586</span></span></div> 
                  <div class="Q5R0i"><span>限时特惠 1.5 折</span></div> <ul class="axYAm"><li><b>127,750</b>点</li><li><b>0.0018</b>元/点</li> <li>无限存储*</li> <li>无限下载/无水印</li> <li>默认开启私密状态</li> <li>可开发票</li></ul></div>
                  <div class="overlay" v-if="currentPlan !='3'"></div>
                </div>
            </div>
            <div style="display: flex;flex-direction: column;align-items: center;">
                <img :src="qrCodeUrl_1" width="122px" v-if="currentPlan === '1'">
                <img :src="qrCodeUrl_2" width="122px" v-if="currentPlan === '2'">
                <img :src="qrCodeUrl_3" width="122px" v-if="currentPlan === '3'">
                <div class = "box-loading" ref="loadconner" v-if="loading"> 二维码加载中...</div>
                <div style="display: flex;flex-direction: row;">
                  <vs-button type="filled"  @click="close">支付成功</vs-button>
                  <vs-button type="filled" >遇到问题</vs-button>
                </div>
                <div style="font-size: 12px;color: #575757; margin-top: 2px; display: flex;flex-direction: row;align-items: center;">
                  <div style="text-align: center; align-items: center;">
                    <span>【师生专享计划】</span>
                  </div>
                  <div class="qrcode"> 
                    <span>点击咨询</span>
                    <div class="code">
                      <img src="helper.jpeg" alt="" width="100px">
                    </div>
                  </div>
                </div>
            </div>
          </section>
        </div>
    </div>
  </template> -->
<template>
  <div>
    <div style="width: 860.30px; height: 618px; position: relative; background: white; box-shadow: 0px 1.9983831644058228px 1.9983831644058228px rgba(0, 0, 0, 0.25); border-radius: 23.98px;overflow: hidden;">
      <div class="tab-1" style="position: absolute; transition: transform 0.3s ease;z-index: 3;">
        <div class="Rectangle3469027" style="width: 861px; height: 618.50px; left: 0px; top: 0px; position: absolute; background: #F6F6F6; border-radius: 24px"></div>
        <div class="Group427322511" style="width: 186px; height: 26.52px; left: 337px; top: 569.74px; position: absolute">
          <div class="Rectangle3469030" style="width: 186px; height: 26.52px; left: 0px; top: 0px; position: absolute; background: rgba(11, 64, 226, 0.19); border-radius: 5.73px"></div>
          <div style="left: 22px; top: 4.66px; position: absolute; color: #1140E5; font-size: 12px; font-family: Alibaba PuHuiTi 2.0; font-weight: 400; word-wrap: break-word">「师生专享计划」点击咨询</div>
        </div>
        <div class="Group427322501" style="width: 753px; height: 335px; left: 54px; top: 209px; position: absolute">
          <div class="Rectangle3469021" @click="changePlan('1')" style="width: 239px; height: 335px; left: 0px; top: 0px; position: absolute; background: white; box-shadow: 0px 2px 56.5px rgba(89.03, 70.66, 201.88, 0.12); border-radius: 16px;"></div>
          <div class="Rectangle3469022" @click="changePlan('2')" style="width: 239px; height: 335px; left: 257px; top: 0px; position: absolute; background: white; box-shadow: 0px 2px 56.5px rgba(89.03, 70.66, 201.88, 0.12); border-radius: 16px"></div>
          <div class="Rectangle3469023" @click="changePlan('3')" style="width: 239px; height: 335px; left: 514px; top: 0px; position: absolute; background: white; box-shadow: 0px 2px 56.5px rgba(89.03, 70.66, 201.88, 0.12); border-radius: 16px"></div>
        </div>
        <div class="Rectangle3469028"></div>
        <div class="choose">
          <div class="Rectangle3469029-1" :style="{opacity:currentPlan=='1'? 0.8:0 }"></div>
          <div class="Rectangle3469029-2" :style="{opacity:currentPlan=='2'? 0.8:0 }"></div>
          <div class="Rectangle3469029-3" :style="{opacity:currentPlan=='3'? 0.8:0 }"></div>
        </div>
        <div class="Group427322502" style="width: 328.16px; height: 62.48px; left: 280px; top: 107px; position: absolute">
          <div style="left: 0px; top: 0px; position: absolute;margin-top: 8px;"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="40" viewBox="0 0 15 45" fill="none">
              <circle cx="7.21956" cy="7.90706" r="6.96956" fill="#1441E6"/>
              <path d="M4.35156 8.11015L7.0164 10.365L10.2962 5.44531" stroke="white" stroke-width="1.22992" stroke-linecap="round"/>
              <circle cx="7.21956" cy="37.4227" r="6.96956" fill="#1441E6"/>
              <path d="M4.35156 37.6258L7.0164 39.8806L10.2962 34.9609" stroke="white" stroke-width="1.22992" stroke-linecap="round"/>
            </svg>
          </div>
          <div style="left: 180px; top: 0px; position: absolute;margin-top: 8px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="40" viewBox="0 0 15 44" fill="none">
              <circle cx="7.51644" cy="7.40706" r="6.96956" fill="#1441E6"/>
              <path d="M4.64844 7.61015L7.31327 9.865L10.5931 4.94531" stroke="white" stroke-width="1.22992" stroke-linecap="round"/>
              <circle cx="7.51644" cy="36.9227" r="6.96956" fill="#1441E6"/>
              <path d="M4.64844 37.1258L7.31327 39.3806L10.5931 34.4609" stroke="white" stroke-width="1.22992" stroke-linecap="round"/>
            </svg>
          </div>

          <div style="margin-top: 6px;left: 34.31px; top: 0px; position: absolute; color: black; font-size: 14.70px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word">无限存储<br/>无限下载/无水印</div>
          <div style="margin-top: 6px;min-width: 200px; left: 212.16px; top: 0.48px; position: absolute; color: black; font-size: 14.70px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word">默认开启私密状态<br/>可开发票</div>
        </div>
        <div class="12400" style="left: 88.50px; top: 437.50px; position: absolute;pointer-events: none;"><span style="color: black; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">可生成</span><span style="color: #1841E9; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">200</span><span style="color: black; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">张图</span></div>
        <div class="46500" style="left: 352px; top: 437.50px; position: absolute;pointer-events: none;"><span style="color: black; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">可生成</span><span style="color: #1641E8; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">800</span><span style="color: black; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">张图</span></div>
        <div class="255500" style="width:200px; left: 608px; top: 437.50px; position: absolute;pointer-events: none;"><span style="color: black; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">可生成</span><span style="color: #1441E6; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">5500</span><span style="color: black; font-size: 19.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">张图</span></div>
        <div class="Group427322503" style="width: 156px; height: 125px; left: 77.50px; top: 254px; position: absolute; pointer-events: none;">
          <div class="Ai" style="left: 11px; top: 0px; position: absolute; color: black; font-size: 20.45px; font-family: Alibaba PuHuiTi 2.0; font-weight: 400; word-wrap: break-word">AI创作初学者</div>
          <div class="Group427322474" style="width: 156px; height: 84.50px; left: 0px; top: 40.50px; position: absolute">
            <div class="599" style="left: 11px; top: 62.50px; position: absolute; color: black; font-size: 15.77px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; text-decoration: line-through; word-wrap: break-word">原价 ￥59.9</div>
            <div class="Group427322472" style="width: 125.26px; height: 69px; left: 0px; top: 0px; position: absolute">
              <div class="9" style="left: 42.26px;position: absolute; color: black; font-size: 49.01px; font-family: Alibaba PuHuiTi 2.0 Alternate; font-weight: 700; word-wrap: break-word">29.9</div>
              <div style="left: 0px; top: 0px; position: absolute; color: black; font-size: 49.01px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">￥</div>
            </div>
          </div>
        </div>
        <div class="Group427322504" style="width: 180px; height: 125px; left: 330.50px; top: 254px; position: absolute; pointer-events: none;" >
          <div class="Ai" style="left: 11px; top: 0px; position: absolute; color: black; font-size: 20.45px; font-family: Alibaba PuHuiTi 2.0; font-weight: 400; word-wrap: break-word">AI创作爱好者</div>
          <div class="Group427322474" style="width: 167px; height: 84.50px; left: 0px; top: 40.50px; position: absolute">
            <div class="213" style="left: 11px; top: 62.50px; position: absolute; color: black; font-size: 15.77px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; text-decoration: line-through; word-wrap: break-word">原价 ￥213</div>
            <div class="Group427322472" style="width: 125.26px; height: 69px; left: 0px; top: 0px; position: absolute">
              <div class="9" style="left: 42.26px; position: absolute; color: black; font-size: 49.01px; font-family: Alibaba PuHuiTi 2.0 Alternate; font-weight: 700; word-wrap: break-word">99.9</div>
              <div style="left: 0px; top: 0px; position: absolute; color: black; font-size: 49.01px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">￥</div>
            </div>
          </div>
        </div>
        <div class="Group427322505" style="width: 174.50px; height: 125px; left: 583.50px; top: 254px; position: absolute; pointer-events: none;; pointer-events: none;">
          <div class="Ai" style="left: 11px; top: 0px; position: absolute; color: black; font-size: 20.45px; font-family: Alibaba PuHuiTi 2.0; font-weight: 400; word-wrap: break-word">AI专业工作者</div>
          <div class="Group427322474" style="width: 174.50px; height: 84.50px; left: 0px; top: 40.50px; position: absolute">
            <div class="1586" style="left: 11px; top: 62.50px; position: absolute; color: black; font-size: 15.77px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; text-decoration: line-through; word-wrap: break-word">原价 ￥1586</div>
            <div class="Group427322472" style="width: 113.26px; height: 69px; left: 0px; top: 0px; position: absolute">
              <div style="left: 42.26px; position: absolute; color: black; font-size: 49.01px; font-family: Alibaba PuHuiTi 2.0 Alternate; font-weight: 700; word-wrap: break-word">635</div>
              <div style="left: 0px; top: 0px; position: absolute; color: black; font-size: 49.01px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">￥</div>
            </div>
          </div>
        </div>
        <div class="Pro" style="left: 353.50px; top: 34px; position: absolute; color: black; font-size: 27.78px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">终身特权</div>
        <div class="Group427322507" @click="changeCurrentPlanandPay('1')" style="width: 203.50px; height: 37.50px; left: 72px; top: 492.50px; position: absolute; cursor: pointer;">
          <div class="Rectangle3469025" :style="{backgroundColor: currentPlan == '1' ? 'blue' : currentPlan == '2' ? '' : ''}"></div>
          <div class="confirm" :style="{color: currentPlan == '1'? 'white' : 'black'}">购 买</div>
        </div>
        <div class="Group427322508" @click="changeCurrentPlanandPay('2')" style="width: 203.50px; height: 37.50px; left: 328.50px; top: 492.50px; position: absolute; cursor: pointer;">
          <div class="Rectangle3469025" :style="{backgroundColor: currentPlan == '1' ? '' : currentPlan == '2' ? 'blue' : ''}"></div>
          <div class="confirm" :style="{color: currentPlan == '2'? 'white' : 'black'}">购 买</div>
        </div>
        <div class="Group427322509" @click="changeCurrentPlanandPay('3')" style="width: 203.50px; height: 37.50px; left: 586px; top: 492.50px; position: absolute; cursor: pointer;">
          <div class="Rectangle3469025" :style="{backgroundColor: currentPlan == '1' ? '' : currentPlan == '2' ? '' : 'blue'}"></div>
          <div class="confirm" :style="{color: currentPlan == '3'? 'white' : 'black'}">购 买</div>
        </div>
        <div class="Group427322512" style="width: 94.50px; height: 22.50px; left: 189.50px; top: 475.50px; position: absolute">
          <div class="Rectangle3469031" style="width: 94.50px; height: 22.50px; left: 0px; top: 0px; position: absolute; background: #D3F024; border-radius: 4px"></div>
          <div class="33" style="left: 8.50px; top: 3px; position: absolute; color: black; font-size: 12px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">限时特惠5.0折</div>
        </div>
        <div class="Group427322513" style="width: 94.50px; height: 22.50px; left: 450.50px; top: 475.50px; position: absolute">
          <div class="Rectangle3469031" style="width: 94.50px; height: 22.50px; left: 0px; top: 0px; position: absolute; background: #D3F024; border-radius: 4px"></div>
          <div class="28" style="left: 8.50px; top: 3px; position: absolute; color: black; font-size: 12px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">限时特惠4.5折</div>
        </div>
        <div class="Group427322514" style="width: 94.50px; height: 22.50px; left: 708.50px; top: 475.50px; position: absolute">
          <div class="Rectangle3469031" style="width: 94.50px; height: 22.50px; left: 0px; top: 0px; position: absolute; background: #D3F024; border-radius: 4px"></div>
          <div class="15" style="left: 8.50px; top: 3px; position: absolute; color: black; font-size: 12px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">限时特惠4.0折</div>
        </div>
      </div>
      
      <div class="tab-2" style="position: absolute;left:900px;transition: transform 0.3s ease;z-index: 2;transition: opacity 0.3s ease;">
        <div class="Rectangle3469027" style="width: 860.30px; height: 618px; left: 0px; top: 0px; position: absolute; background: #F6F6F6; border-radius: 23.98px"></div>
          <div class="Rectangle3469028" style="width: 752.39px; height: 77.94px; left: 53.96px; top: 99.42px; position: absolute; opacity: 0.10; background: rgba(62.69, 70.38, 255, 0.74); border-radius: 10.49px"></div>
          <div style="width:100px; left: 402.67px; top: 33.97px; position: absolute; color: black; font-size: 27.75px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">支付</div>
          <div style="width: 130px;height: 130px;">
            <img class="2023090609251" style="width: 127.98px; height: 127.98px; left: 357.68px; top: 323.91px; position: absolute; border-radius: 4.21px" :src="currentPlan=='1' ? qrCodeUrl_1 : currentPlan=='2' ? qrCodeUrl_2 : qrCodeUrl_3"/>
          </div>  
          <div class="Group427322520" style="left: 384.02px; top: 461.38px; position: absolute">
            <div style="width:100px;left: 0px; top: 3.69px; position: absolute; color: black; font-size: 12.64px; font-family: Alibaba PuHuiTi 2.0; font-weight: 400; word-wrap: break-word">微信支付</div>
            <div class="RiWechatPayFill" style="width: 24.88px; height: 24.88px; left: 56.88px; top: 0px; position: absolute; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: inline-flex;">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path d="M10.5056 15.5853C10.3471 15.6665 10.1633 15.6828 9.99299 15.6307C9.82273 15.5787 9.67941 15.4624 9.59339 15.3065L9.54882 15.208L7.66436 11.0639C7.65653 11.0137 7.65653 10.9627 7.66436 10.9125C7.66435 10.8673 7.67338 10.8225 7.69093 10.7809C7.70848 10.7392 7.73419 10.7015 7.76655 10.6699C7.79891 10.6383 7.83726 10.6135 7.87935 10.597C7.92143 10.5804 7.9664 10.5725 8.01161 10.5736C8.08523 10.572 8.15708 10.5962 8.21477 10.642L10.4745 12.2248C10.642 12.337 10.8388 12.3972 11.0404 12.3979C11.1619 12.3986 11.2824 12.3753 11.3949 12.3295L21.8092 7.66499C20.7411 6.48531 19.4323 5.54858 17.9712 4.91806C16.51 4.28754 14.9306 3.97791 13.3395 4.01009C7.61149 4.01009 2.96875 7.87645 2.96875 12.684C3.00231 13.9791 3.34541 15.2473 3.96935 16.3827C4.5933 17.5181 5.47997 18.4876 6.55524 19.2102C6.66966 19.2945 6.75505 19.4124 6.79961 19.5474C6.84418 19.6823 6.84571 19.8278 6.80401 19.9638L6.33756 21.7269C6.30921 21.8072 6.29144 21.8908 6.2847 21.9757C6.28371 22.0214 6.29199 22.0669 6.30904 22.1094C6.32608 22.1518 6.35155 22.1904 6.3839 22.2227C6.41625 22.2551 6.45481 22.2805 6.49726 22.2976C6.53971 22.3146 6.58517 22.3229 6.63091 22.3219C6.70045 22.3181 6.76803 22.2975 6.82786 22.2618L9.08755 20.9506C9.2534 20.849 9.44309 20.794 9.63693 20.793C9.74058 20.793 9.84217 20.8075 9.9396 20.8376C11.0404 21.1485 12.1786 21.3061 13.3229 21.304C19.0499 21.304 23.6999 17.4387 23.6999 12.6695C23.7103 11.247 23.3156 9.85094 22.5618 8.64454L10.5885 15.5397L10.5056 15.5853Z" fill="black"/>
              </svg>
            </div>
          </div>
          <div class="Group427322474" style="width: 151.51px; height: 88.96px; left: 348.08px; top: 215.83px; position: absolute">
            <div class="599" style="left: 39.62px; top: 65.96px; position: absolute; color: black; font-size: 16.61px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; text-decoration: line-through; word-wrap: break-word">原价 {{ currentPlan=='1'?'￥59.9': currentPlan=='2'? '￥213': '￥1586'}}</div>
            <div class="Group427322472" style="width: 151.51px; height: 72.12px; left: 0px; top: 0px; position: absolute">
              <div class="9" style="left: 44.51px; top: 0px; position: absolute; color: black; font-size: 51.62px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">{{ currentPlan=='1'?29.9: currentPlan=='2'? 99.9:635}}</div>
              <div style="left: 0px; top: 0.12px; position: absolute; color: black; font-size: 51.62px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">￥</div>
            </div>
          </div>
          <div class="Group427322512" style="width: 99.54px; height: 23.70px; left: 453.54px; top: 203.83px; position: absolute">
            <div class="Rectangle3469031" style="width: 99.54px; height: 23.70px; left: 0px; top: 0px; position: absolute; background: #D3F024; border-radius: 4.21px"></div>
            <div class="33" style="left: 8.95px; top: 3.16px; position: absolute; color: black; font-size: 12.64px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">限时特惠{{ currentPlan=='1'? '5.0折': currentPlan=='2'? '4.5折':'4.0折' }}</div>
          </div>
          <div class="Group427322518" style="width: 318.74px; height: 46.87px; left: 270.78px; top: 521.42px; position: absolute">
            <div class="Group427322507" style="width: 155.62px; height: 46.87px; left: 0px; top: 0px; position: absolute">
              <div class="Rectangle3469025" style="width: 155.62px; height: 46.87px; left: 0px; top: 0px; position: absolute; background: linear-gradient(95deg, #3F46FF 0%, #003DDB 100%); border-radius: 13.12px"></div>
              <div @click="verifyPaid" style="left: 37.50px; top: 8.75px; position: absolute; color: white; font-size: 20.52px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word;cursor: pointer;">支付成功</div>
            </div>
            <div class="Group427322517" style="width: 155.62px; height: 46.87px; left: 163.12px; top: 0px; position: absolute">
              <div class="Rectangle3469025" style="width: 155.62px; height: 46.87px; left: 0px; top: 0px; position: absolute; background: rgba(16, 64, 228, 0.10); border-radius: 13.12px"></div>
              <div style="left: 37.50px; top: 8.75px; position: absolute; color: #1140E4; font-size: 20.52px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word;cursor: pointer;">遇到问题</div>
            </div>
          </div>
          <div class="Vector1381" @click="backtoTab1" style="width: 11.99px; height: 19.98px; left: 57.95px; top: 43.46px; position: absolute;cursor: pointer;">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="23" viewBox="0 0 14 23" fill="none">
            <path d="M12.9434 1.46484L1.87424 10.6892C1.39488 11.0886 1.39488 11.8249 1.87425 12.2244L12.9434 21.4487" stroke="black" stroke-width="2.99757"/>
          </svg>
          </div>
          <div class="Group427322521" style="width: 515.67px; height: 62.48px; left: 172.86px; top: 106.91px; position: absolute">
            <div class="Group427322502" style="display:flex;flex-direction: row; width: 388.62px; height: 62.48px; left: 0px; top: 0px; position: absolute">
              <div style="position: relative;left:0px;margin-top: 8px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="40" viewBox="0 0 15 45" fill="none">
                  <circle cx="7.82721" cy="7.5733" r="6.96393" fill="#1441E6"/>
                  <path d="M4.95703 7.77987L7.61971 10.0329L10.8969 5.11719" stroke="white" stroke-width="1.22893" stroke-linecap="round"/>
                  <circle cx="7.82721" cy="37.0655" r="6.96393" fill="#1441E6"/>
                  <path d="M4.95703 37.2721L7.61971 39.5251L10.8969 34.6094" stroke="white" stroke-width="1.22893" stroke-linecap="round"/>
                </svg>
              </div>
              <div style="position: relative; left:165px;margin-top: 8px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="40" viewBox="0 0 14 44" fill="none">
                  <circle cx="6.97955" cy="7.0733" r="6.96393" fill="#1441E6"/>
                  <path d="M4.10938 7.27987L6.77205 9.5329L10.0492 4.61719" stroke="white" stroke-width="1.22893" stroke-linecap="round"/>
                  <circle cx="6.97955" cy="36.5655" r="6.96393" fill="#1441E6"/>
                  <path d="M4.10938 36.7721L6.77205 39.0251L10.0492 34.1094" stroke="white" stroke-width="1.22893" stroke-linecap="round"/>
                </svg>
              </div>
              <div style="position: relative;left:335px;margin-top: 8px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="40" viewBox="0 0 15 44" fill="none">
                  <circle cx="7.51862" cy="7.0733" r="6.96393" fill="#1441E6"/>
                  <path d="M4.64844 7.27987L7.31112 9.5329L10.5883 4.61719" stroke="white" stroke-width="1.22893" stroke-linecap="round"/>
                  <circle cx="7.51862" cy="36.5655" r="6.96393" fill="#1441E6"/>
                  <path d="M4.64844 36.7721L7.31112 39.0251L10.5883 34.1094" stroke="white" stroke-width="1.22893" stroke-linecap="round"/>
                </svg>
              </div>
              <div style="margin-top: 3px; left: 34.28px; top: 0px; position: absolute; color: black; font-size: 14.69px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word; line-height: 1.7;">fast 1点/张<br/>relax 0.25点/张</div>
              <div style="margin-top: 3px; left: 211.99px; top: 0px; position: absolute; color: black; font-size: 14.69px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word; line-height: 1.7;">AI自动提示词限时免费<br/>可开发票</div>
            </div>
            <div class="5012400" style="margin-top: 3px; width: 200px;left: 401.67px; top: 0px; position: absolute;line-height: 1.7;"><span style="color: black; font-size: 14.69px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word">{{ currentPlan=='1'? '200点': currentPlan=='2'? '800点':'5500点' }}<br/></span><span style="color: black; font-size: 14.69px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word">可生成</span><span style="color: black; font-size: 14.69px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word">{{ currentPlan=='1'? '200张mj-fast + 画板': currentPlan=='2'? '800张mj-fast + 画板':'5500张mj-fast + 画板' }}</span><span style="color: black; font-size: 14.69px; font-family: Alibaba PuHuiTi 2.0; font-weight: 300; word-wrap: break-word"></span></div>
          </div>
      </div>

      <div class="tab-3" style="position: relative; background: white; border-radius: 23.98px;opacity: 0;z-index: 0;transition: opacity 0.5s ease;">
        <div class="Rectangle3469028" style="width: 860.30px; height: 618px; left: 0px; top: 0px; position: absolute; background: #F6F6F6; border-radius: 23.98px"></div>
        <div class="Group427322517" style="left: 301.66px; top: 243.34px; position: absolute">
          <div style="width:300px; left: 61.28px; top: 0px; position: absolute; color: #1441E6; font-size: 34.42px; font-family: Alibaba PuHuiTi 2.0; font-weight: 900; word-wrap: break-word">支付成功</div>
          <div class="Ai" style="width:400px;left: 0px; top: 56.99px; position: absolute; color: #1441E6; font-size: 21.98px; font-family: Alibaba PuHuiTi 2.0; font-weight: 400; word-wrap: break-word">{{ currentPlan=='1'? '恭喜成为「AI创作初学者」': currentPlan=='2'? '恭喜成为「AI创作爱好者」':'恭喜成为「AI专业工作者」' }}</div>
        </div>
        <div class="Group427322522" @click="close" style="width: 19.04px; height: 20.98px; left: 781.68px; top: 39.41px; position: absolute; cursor: pointer;">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 45 49" fill="none">
            <path d="M3.73828 3.88281L41.7383 45.8828" stroke="black" stroke-opacity="0.8" stroke-width="6" stroke-linecap="round"/>
            <path d="M41.6289 3.88281L3.62891 45.8828" stroke="black" stroke-opacity="0.8" stroke-width="6" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="Group427322523" style="width: 99.92px; height: 99.92px; left: 380.50px; top: 132.89px; position: absolute">
          <img width="" height="" src="paid_1.png">
        </div>
        <div class="Group427322524" style="width: 381.19px; height: 381.19px; left: 415.04px; top: 132.89px; position: absolute; opacity: 1">
          <img width="" height="" src="paid_back.png">
        </div>
        <div class="Group427322472" style="width: 151.50px; height: 73.62px; left: 351.99px; top: 371.58px; position: absolute">
          <div class="9" style="left: 44.50px; top: 1.62px; position: absolute; color: black; font-size: 51.62px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">{{ currentPlan=='1'? '29.9': currentPlan=='2'? '99.9':'635' }}</div>
          <div style="left: 0px; top: 0px; position: absolute; color: black; font-size: 51.62px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word">￥</div>
        </div>
        <div class="Group427322507" style="width: 155.62px; height: 46.87px; left: 351.99px; top: 467.01px; position: absolute">
          <div class="Rectangle3469025" style="width: 155.62px; height: 46.87px; left: 0px; top: 0px; position: absolute; background: linear-gradient(95deg, #3F46FF 0%, #003DDB 100%); border-radius: 13.12px"></div>
          <div @click="close" style="left: 37.47px; top: 8.98px; position: absolute; color: white; font-size: 20.52px; font-family: Alibaba PuHuiTi 2.0; font-weight: 700; word-wrap: break-word;cursor: pointer;">继续使用</div>
        </div>
        <div class="AiPro" style="left: 352.25px; top: 360.06px; position: absolute; color: black; font-size: 11.99px; font-family: Alibaba PuHuiTi 2.0; font-weight: 400; word-wrap: break-word">{{ currentPlan=='1'? 'AI创作初学者': currentPlan=='2'? 'AI创作爱好者':'AI专业工作者' }} Pro会员购买</div>
      </div>
      </div>
  </div>
</template>
  <script>
  import axios from 'axios';
  import md5 from 'md5';
  import unirest from 'unirest';
  import qs from 'qs';
  import { EventBus } from '../event-bus';
  
  export default {
    data() {
      return {
        name: 'Pay',
        currentPlan: '1',
        qrCodeUrl_1: '',
        qrCodeUrl_2: '',
        qrCodeUrl_3: '',
        loading: false,
      };
    },
    props: {
      user_id: {
        type: String,
        default: null,
      },
    },
    created() {
      this.getPaymentQrCode();
    },
    methods: {
      async getPaymentQrCode() {
        this.loading = true;
        let self = this;
        // console.log('user_id', this.user_id)
        const order_id_1 = this.orderCode();
        const order_id_2 = this.orderCode();
        const order_id_3 = this.orderCode();
        try {
            let params1 = {
                mch_id: '1648997594',
                out_trade_no: String(order_id_1),
                total_fee: '29.9',
                body: 'VIP会员',
                timestamp: String(Math.floor(new Date().getTime() / 1000)),
                notify_url: 'http://api.arti-id.com:8000/api/v2/recallpay/',
            }
            let params2 = {
                mch_id: '1648997594',
                out_trade_no: String(order_id_2),
                total_fee: '99.9',
                body: 'VIP会员',
                timestamp: String(Math.floor(new Date().getTime() / 1000)),
                notify_url: 'http://api.arti-id.com:8000/api/v2/recallpay/',
            }
            let params3 = {
                mch_id: '1648997594',
                out_trade_no: String(order_id_3),
                total_fee: '635',
                body: 'VIP会员',
                timestamp: String(Math.floor(new Date().getTime() / 1000)),
                notify_url: 'http://api.arti-id.com:8000/api/v2/recallpay/',
            }
            const sign1 = this.wxPaySign(params1, 'ca76299b1bb0fdaff10d81a5033d0813');
            const sign2 = this.wxPaySign(params2, 'ca76299b1bb0fdaff10d81a5033d0813');
            const sign3 = this.wxPaySign(params3, 'ca76299b1bb0fdaff10d81a5033d0813');
            // console.log(sign);
            params1.sign = sign1;
            params2.sign = sign2;
            params3.sign = sign3;
            params1.attach = this.user_id;
            params2.attach = this.user_id;
            params3.attach = this.user_id;
            const formData1 = qs.stringify(params1);
            const formData2 = qs.stringify(params2);
            const formData3 = qs.stringify(params3);
        //   params.time_expire = 'h:1'S
            const response1 = await axios.post('/pay/api/wxpay/native', formData1, {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            },);
            const response2 = await axios.post('/pay/api/wxpay/native', formData2, {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            },);
            const response3 = await axios.post('/pay/api/wxpay/native', formData3, {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            },);
            // const response1 = await axios.post('wxpay/native', formData1, {
            //     headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded',
            //     },
            // },);
            // const response2 = await axios.post('/wxpay/native', formData2, {
            //     headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded',
            //     },
            // },);
            // const response3 = await axios.post('/wxpay/native', formData3, {
            //     headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded',
            //     },
            // },);
            // console.log(response1);
            // console.log(response2);
            // console.log(response3);
        //     // build
        //     // const response = await axios.post('/pay/api/wxpay/native', formData, {
        //     //     headers: {
        //     //     'Content-Type': 'application/x-www-form-urlencoded',
        //     //     },
        //     // },);           
        //     // console.log(response);
            this.qrCodeUrl_1 = response1.data.data.QRcode_url;
            this.qrCodeUrl_2 = response2.data.data.QRcode_url;
            this.qrCodeUrl_3 = response3.data.data.QRcode_url;

            const order_params_1 = {
                order_id: order_id_1,
                user_id: self.user_id,
            }
            const order_params_2 = {
                order_id: order_id_2,
                user_id: self.user_id,
            }
            const order_params_3 = {
                order_id: order_id_3,
                user_id: self.user_id,
            }
            const order_response1 = await axios.post('/api/v2/order/orders/', order_params_1); 
            const order_response2 = await axios.post('/api/v2/order/orders/', order_params_2); 
            const order_response3 = await axios.post('/api/v2/order/orders/', order_params_3); 
            this.loading = false;
            // console.log(response);
            } catch (error) {
            console.error(error);
            }
        },
      async testpay(){

        try{
            const params = {
            order_id:'123',
            user_id:'123',
        }
        const response = await axios.post('/api/v2/order/orders/', params);

        // console.log(response);

        } catch (error) {
            console.error(error);
        }
      },

      close() {
        EventBus.$emit('updateHistory');
        this.$parent.close()
      },

      generateUniqueRandomNumber() {
        let randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
        return randomNumber;
      },
      orderCode() {
          var orderCode='';
          for (var i = 0; i < 6; i++) //6位随机数
          {
              orderCode += Math.floor(Math.random() * 10);
          }
          orderCode = new Date().getTime() + orderCode;  //时间戳，用来生成订单号。
          console.log(orderCode)
          return orderCode;
      },
      wxPaySign(params, key) {
        const paramsArr = Object.keys(params);
        paramsArr.sort();
        const stringArr = [];
        paramsArr.map(key => {
            stringArr.push(key + '=' + params[key]);
        });
        // 最后加上商户Key
        stringArr.push("key=" + key);
        const string = stringArr.join('&');
        return md5(string).toString().toUpperCase();
      },
      changePlan(plan) {
        this.currentPlan = plan;
      },
      changeCurrentPlanandPay(plan) {
        this.currentPlan = plan;
        var tab1 = document.getElementsByClassName("tab-1")[0];
        var tab2 = document.getElementsByClassName("tab-2")[0];
        tab1.style.transform = 'translateX(-900px)';
        tab2.style.transform = 'translateX(-900px)';
      },
      backtoTab1(){
        var tab1 = document.getElementsByClassName("tab-1")[0];
        var tab2 = document.getElementsByClassName("tab-2")[0];
        tab1.style.transform = 'translateX(0px)';
        tab2.style.transform = 'translateX(0px)';
      },
      verifyPaid() {
        var tab2 = document.getElementsByClassName("tab-2")[0];
        var tab3 = document.getElementsByClassName("tab-3")[0];
        tab2.style.opacity = '0';
        tab3.style.opacity = '1';
        tab2.style.zIndex = '0';
        tab3.style.zIndex = '3';
      }
    },
  };
  </script>

<style lang="scss" scoped>
.choose{
  display: flex;
  flex-direction: row;
  position: relative;
  left:0px;
  top:130px;
  pointer-events: none;
}
.Rectangle3469029-1{
  width: 239.50px; 
  height: 335px; 
  left: 54px; 
  // top: 130px; 
  position: relative; 
  opacity: 0.80; 
  border-radius: 19px;
  background: linear-gradient(231deg, #1040E4 0%, rgba(63, 71, 255, 0) 100%);
  border: 1.25px #061FFF solid;
  transition: opacity 0.3s;
}
.Rectangle3469029-2{
  width: 239.50px; 
  height: 335px; 
  left: 70px; 
  // top: 130px; 
  position: relative; 
  opacity: 0.80; 
  border-radius: 19px;
  background: linear-gradient(231deg, #1040E4 0%, rgba(63, 71, 255, 0) 100%);
  border: 1.25px #061FFF solid;
  transition: opacity 0.3s;
}
.Rectangle3469029-3{
  width: 239.50px; 
  height: 335px; 
  left: 89px; 
  // top: 130px; 
  position: relative; 
  opacity: 0.80; 
  border-radius: 19px;
  background: linear-gradient(231deg, #1040E4 0%, rgba(63, 71, 255, 0) 100%);
  border: 1.25px #061FFF solid;
  transition: opacity 0.3s;
}

.Rectangle3469028{
  width: 753px; 
  height: 78px; 
  left: 54px; 
  top: 99.50px; 
  position: relative; 
  opacity: 0.10; 
  background: rgba(62.69, 70.38, 255, 0.74); 
  border-radius: 10.50px
}
.Rectangle3469025{
  width: 203.50px; 
  height: 37.50px; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
  border-radius: 10.50px; 
  border: 0.25px black solid;
  transition: background-color 0.3s;
}
.confirm{
  width: 38.26px; 
  left: 82.50px; 
  top: 7px; 
  position: absolute; 
  font-size: 16.42px; 
  font-family: Alibaba PuHuiTi 2.0; 
  font-weight: 700; 
  word-wrap: break-word;
  transition: color 0.3s;
}
</style>